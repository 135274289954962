import { httpService } from '../helpers'
import { loginAction } from '../actions'

export const chatAction = {
  openChat,
  getChat,
  sendMessage,
  getFile,
  selectChat,
  getChatMessages,
  getMessageNotification,
  removeMessageNotification,
  translateMessage,
  showOriginalMessage,
}

function openChat(isChatEnabled) {
  return (dispatch) => {
    dispatch({
      isChatEnabled: isChatEnabled,
      type: 'IS_CHAT_ENABLED',
    })
  }
}

function getChat(payload) {
  return (dispatch) => {
    let apiEndpoint = '/chat/brand-chat'
    httpService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'GET_CHAT',
            chats: response.data.result,
            company: response.data.company,
            retailer: payload,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getChatMessages(payload) {
  return (dispatch) => {
    let apiEndpoint = '/chat/' + payload.chat_id
    httpService
      .get(apiEndpoint, {}, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'GET_CHAT_MESSAGES',
            messages: response.data.result,
            chat_id: payload.chat_id,
            brand_id: payload.brand_id,
          })
          if (payload.unread) {
            dispatch({
              type: 'REMOVE_NEW_MESSAGE_NOTIFICATION',
            })
          }
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function sendMessage(payload) {
  return (dispatch) => {
    let apiEndpoint = '/chat'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'SEND_MESSAGE',
            chat: response.data.chat,
            message: response.data.message,
            chat_id: response.data.chat_id,
            newChat: response.data.newChat,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getMessageNotification() {
  return (dispatch) => {
    let apiEndpoint = '/chat/notification'
    httpService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'NEW_MESSAGE_NOTIFICATION',
            result: response.data.result,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function removeMessageNotification() {
  return (dispatch) => {
    dispatch({
      type: 'REMOVE_MESSAGE_NOTIFICATION',
    })
  }
}

function selectChat(id) {
  return (dispatch) => {
    dispatch({
      type: 'SELECT_CHAT',
      chat_id: id,
    })
  }
}

function getFile(file) {
  return (dispatch) => {
    let apiEndpoint = '/chat/file/' + file
    httpService
      .get(apiEndpoint, 'blob')
      .then((response) => {
        const file = new Blob([response.data], { type: response.data.type })
        const url = URL.createObjectURL(file)
        window.open(url)
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function translateMessage(payload) {
  return (dispatch) => {
    let apiEndpoint = '/chat/translate'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'TRANSLATE_MESSAGE',
            translation: response.data.result,
            payload: payload,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function showOriginalMessage(payload) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_ORIGINAL_MESSAGE',
      payload: payload,
    })
  }
}
