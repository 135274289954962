interface NetworkState {
  network: any[]
  count: any
  company: any
  name: string
  current_network: any
  existingUser: any
}

const initialState: NetworkState = {
  network: [],
  count: {
    all: 0,
    uncontacted: 0,
    contacted: 0,
    signed_up: 0,
    ordered: 0,
  },
  company: {},
  name: '',
  current_network: {
    primary_address: {},
  },
  existingUser: undefined,
}

export function network(state = initialState, action: any) {
  switch (action.type) {
    case 'RESET_NETWORK':
      return { ...initialState, count: state.count }
    case 'COUNT_NETWORKS':
      return {
        ...state,
        count: action.count,
      }
    case 'LIST_NETWORKS':
      return {
        ...state,
        network: state.network.concat(action.network),
        count: {
          ...state.count,
          ...action.count,
        },
        company: action.company,
      }
    case 'GET_DETAILS':
      let network: any = {
        ...action.current_network,
      }
      network.recent_chat = action.chat?.chat
      network.recent_orders = action.orders
      network.note = action.network.note
      network.is_referral = action.network.is_referral
      network.network_status = action.network.status
      network.cart_values = action.cartValues
      network.direct_total = action.directTotals.total
      network.brand = action.brand
      network.activity = action.activity[0]
      return {
        ...state,
        current_network: network,
      }
    case 'GET_NETWORK_INFO':
      let values =
        action?.result &&
        Object.keys(action?.result?.company_values)
          .filter(
            (key) =>
              key !== 'id' &&
              key !== 'company_id' &&
              key !== 'createdAt' &&
              key !== 'updatedAt'
          )
          .filter((key) => action.result.company_values[key])
      return {
        ...state,
        current_network: {
          ...state.current_network,
          addresses: action.result?.addresses,
          company_values: values,
          product_categories: action.result?.product_categories,
          retail_channel: action.result?.retail_channel,
        },
      }
    case 'GET_NETWORK_ORDERS':
      return {
        ...state,
        current_network: {
          ...state.current_network,
          buy_orders: action.result,
        },
      }
    case 'UPDATED':
      return state
    case 'HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value,
      }
    case 'EDIT_NOTE':
      return {
        ...state,
        current_network: {
          ...state.current_network,
          note: action.note,
        },
      }
    case 'SEND_INVITE':
      return {
        ...state,
        current_network: {
          ...state.current_network,
          network_status: 'contacted',
        },
      }
    case 'USER_LOOKUP':
      return {
        ...state,
        existingUser: action.existingUser,
      }
    case 'EXTEND_COUPON_DATE':
      let tempNetwork = { ...state.current_network }
      let coupon = tempNetwork.retailer_coupons.find(
        (c) => c.id === action.coupon_id
      )
      if (coupon) {
        let date = new Date(coupon.expiry_date)
        date.setDate(date.getDate() + 7)
        coupon.expiry_date = date
      }
      return {
        ...state,
        current_network: tempNetwork,
      }
    default:
      return state
  }
}
