const initialState = {
  overall_rating: 0,
  total_reviews: 0,
  total_5_stars: 0,
  reviews: [],
  count: 0,
  total_orders: 0,
}

export function review(state = initialState, action) {
  switch (action.type) {
    case 'REVIEW/SUMMARY':
      return {
        ...state,
        overall_rating: action.overall_rating,
        total_reviews: action.total_reviews,
        total_5_stars: action.total_5_stars,
      }
    case 'REVIEW/LIST':
      return {
        ...state,
        reviews: state.reviews.concat(action.reviews),
        count: action.count,
        total_orders: action.total_orders,
      }
    case 'REVIEW/RESET':
      return {
        ...state,
        reviews: initialState.reviews,
      }
    default:
      return state
  }
}
