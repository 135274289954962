import { loginAction } from '.'
import { httpService } from '../helpers'

const getWidgetData = () => (dispatch) => {
  const apiEndpoint = '/widget'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'WIDGET/GET_DATA',
          brand: response.data.result
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const widgetAction = {
  getWidgetData,
}
