export const formatPrice: any = (num: number) => {
  if (num !== null && num !== undefined && !isNaN(num)) {
    const originalPrice = parseFloat(num.toString())
    const originalPriceWithTwoDecimals = originalPrice.toFixed(2)
    return originalPriceWithTwoDecimals
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    return '0.00'
  }
}
