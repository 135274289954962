const initialState = {
  brand: { products: [], reviews: [], company_values: {}, collections: [] },
  product: {
    company: {},
    variations: [],
    photos: [],
    lifestyle: [],
    packaging: [],
  },
  categories: [],
  cart: { items: [], subtotal: null },
  related_products: [],
}
export function brand(state = initialState, action) {
  switch (action.type) {
    case 'GET_BRAND':
      let temp = action.result.products.rows.reduce((allCategories, p) => {
        let categoryArr = p.product_categories.reduce((categories, pc) => {
          categories.push({
            en: pc.subcategory && pc.subcategory.en,
            zh_CN: pc.subcategory && pc.subcategory.zh_CN,
            zh_TW: pc.subcategory && pc.subcategory.zh_TW,
          })
          return categories
        }, [])
        let categorySet = new Set([...allCategories, ...categoryArr])
        return [...categorySet]
      }, [])

      state.categories = [...state.categories, ...temp]
      return {
        ...state,
        brand: {
          ...action.result.brand,
          products: state.brand.products.concat(action.result.products),
          count: action.result.count,
          collections: action.result.collections,
        },
      }
    case 'GET_PRODUCT':
      return {
        ...state,
        product: action.result.product,
        related_products: action.result.related,
      }
    case 'SORT_WHOLESALE_DESCENDING':
      return {
        ...state,
        brand: {
          ...state.brand,
          products: state.brand.products.sort((a, b) => {
            return b.wholesale_price - a.wholesale_price
          }),
        },
      }
    case 'SORT_WHOLESALE_ASCENDING':
      return {
        ...state,
        brand: {
          ...state.brand,
          products: state.brand.products.sort((a, b) => {
            return a.wholesale_price - b.wholesale_price
          }),
        },
      }
    case 'RESET_BRAND_STATE':
      return {
        ...initialState,
      }
    default:
      return state
  }
}
