import { loginAction } from '.'
import { httpService } from '../helpers'

const getSummary = (daysBack = 0) => (dispatch) => {
  const apiEndpoint = '/review/summary'
  httpService
    .get(apiEndpoint, {}, { daysBack: daysBack })
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'REVIEW/SUMMARY',
          overall_rating: parseFloat(response.data.result.reviews.avg_rating),
          total_reviews: response.data.result.reviews.count,
          total_5_stars: response.data.result.count_5_reviews,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const getReviews = (daysBack = 0, params) => (dispatch) => {
  const apiEndpoint = '/review'
  httpService
    .get(apiEndpoint, {}, { daysBack: daysBack, ...params })
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'REVIEW/LIST',
          reviews: response.data.result.reviews.rows,
          count: response.data.result.reviews.count,
          total_orders: response.data.result.count_orders,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const resetReviews = () => (dispatch) => {
  dispatch({ type: 'REVIEW/RESET' })
}

export const reviewAction = {
  getSummary,
  getReviews,
  resetReviews,
}
