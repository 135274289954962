const initialState = {
  isChatEnabled: false,
  chat: [],
  profile_photo: '',
  chatToMessages: {},
  newMessageNotification: 0,
  selectedChatId: null,
  translationChange: false,
}

export function chat(state = initialState, action) {
  switch (action.type) {
    case 'IS_CHAT_ENABLED':
      return {
        ...state,
        isChatEnabled: action.isChatEnabled,
      }
    case 'GET_CHAT':
      let inx = null
      let chats = action.chats
      let tempChat = null
      let chatId = null
      let openSpecificChat = false

      if (action.retailer) {
        chats.forEach((each, i) => {
          if (!tempChat)
            tempChat = each.chat.chat_participants.find((c) => {
              if (c.company_id === action.retailer.id) {
                inx = i
              }
              return c.company_id === action.retailer.id
            })
        })

        if (inx != null) {
          chatId = chats[inx]?.chat_id
        } else {
          let fakeChat = {
            chat_id: -1,
            chat: {
              id: -1,
              company_id: action.retailer.id,
              last_message: null,
              updated_at: Date.now(),
              chat_participants: [
                {
                  id: action.retailer.id,
                  company_id: action.retailer.id,
                  unread: false,
                  company: {
                    id: action.retailer.id,
                    name: action.retailer.name,
                    profile_photo: action.retailer.profile_photo,
                    primary_contact: {
                      email: action.retailer.email,
                    },
                  },
                },
                {
                  id: action.retailer.brand_id,
                  company_id: action.retailer.brand_id,
                  unread: false,
                  company: {
                    id: action.retailer.brand_id,
                    name: action.retailer.brand_name,
                  },
                },
              ],
            },
          }
          chats.unshift(fakeChat)
          chatId = -1
        }
        openSpecificChat = true
      }

      let chatsTemp = action.chats
      if (state.chat?.length > 0 && state.chat?.length > action.chats.length) {
        let fakeChat = state.chat.find((ch) => ch.chat_id === -1)
        chatId = -1
        chatsTemp.splice(state.chat.indexOf(fakeChat), 0, fakeChat)
      }
      return {
        ...state,
        chat: chatsTemp,
        selectedChatId:
          state.selectedChatId && !openSpecificChat
            ? state.selectedChatId
            : chatId,
        profile_photo: action.company?.profile_photo,
      }
    case 'GET_CHAT_MESSAGES':
      let i = 0
      let mapTo = {}
      state.chat.forEach((e, inx) => {
        if (e.chat_id === action.chat_id) i = inx
      })
      state.chat[i].chat.chat_participants.find(
        (c) => c.company_id === action.brand_id
      ).unread = false

      if (
        action.chat_id in state.chatToMessages &&
        state.chatToMessages[action.chat_id].length > action.messages.length
      ) {
        mapTo = {
          ...state.chatToMessages,
          [action.chat_id]: state.chatToMessages[action.chat_id].concat(
            action.messages
          ),
        }
      } else {
        mapTo = {
          ...state.chatToMessages,
          [action.chat_id]: action.messages,
        }
      }
      return {
        ...state,
        chatToMessages: mapTo,
      }

    case 'SEND_MESSAGE':
      let index = 0
      let temp = state.chat.filter((c) => c.chat_id !== -1)
      let currentChat = temp.find((e, i) => {
        if (e.chat_id === action.chat_id) index = i
        return e.chat_id === action.chat_id
      })

      let messages = state.chatToMessages[action.chat_id]
      if (messages) {
        messages.push(action.message)
      } else messages = [action.message]

      if (currentChat) {
        currentChat.chat.last_message = action?.chat?.last_message
        currentChat.chat.updated_at = action?.chat?.updatedAt
        temp.unshift(currentChat)
        temp.splice(++index, 1)
      } else if (action.newChat) {
        temp.unshift(action.newChat)
      }

      return {
        ...state,
        chat: temp,
        selectedChatId: action.chat_id,
        chatToMessages: {
          ...state.chatToMessages,
          [action.chat_id]: messages,
        },
        translationChange: false,
      }
    case 'SELECT_CHAT':
      return {
        ...state,
        selectedChatId: action.chat_id,
      }
    case 'NEW_MESSAGE_NOTIFICATION':
      return {
        ...state,
        newMessageNotification: action.result,
      }
    case 'REMOVE_MESSAGE_NOTIFICATION':
      return {
        ...state,
        newMessageNotification: 0,
      }
    case 'TRANSLATE_MESSAGE':
      let message =
        state.chatToMessages[action.payload.chat_id][action.payload.index]
      message.translated = true
      message.text = action.translation
      message.originalText = action.payload.message
      return {
        ...state,
        chatToMessages: state.chatToMessages,
        translationChange: true,
      }
    case 'SHOW_ORIGINAL_MESSAGE':
      let msg =
        state.chatToMessages[action.payload.chat_id][action.payload.index]
      msg.text = msg.originalText
      msg.translated = false
      return {
        ...state,
        chatToMessages: state.chatToMessages,
      }
    default:
      return state
  }
}
