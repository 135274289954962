import { crypt } from '../helpers'

const initialState = {
  order: [],
  count: 0,
  current_order: {},
  network: [],
  product: [],
  selected_items: [],
  target_shipment: null,
  supplier: '',
  close_forms: false,
  manual_order: {},
  manual_order_draft: {
    order_variations: [],
  },
  postmen_errors: [],
  company: {},
}
export function order(state = initialState, action) {
  const userInfo = crypt.getUserInfo()
  switch (action.type) {
    case 'RESET_ORDER':
      return { ...initialState }
    case 'LIST_ORDERS':
      let orders = []
      if (action.isFilter) {
        orders = action.order
      } else {
        orders = state.order.concat(action.order)
      }
      return {
        ...state,
        order: orders,
        count: action.count,
        company: action.company,
      }
    case 'GET_ORDER_DETAILS':
      let activity_feed_reply = {}
      if (action.current_order) {
        action.current_order.order_activities.forEach((a) => {
          activity_feed_reply[a.id] = { open: false }
          a.order_activity_replies.forEach((r) => {
            activity_feed_reply[a.id][r.id] = { open: false }
          })
        })
      }
      return {
        ...state,
        current_order: {
          ...action.current_order,
          shipping: action.shipping,
        },
        activity_feed_reply: activity_feed_reply,
      }
    case 'GET_ORDER_FOR_EDIT':
      return {
        ...state,
        current_order: action.current_order,
      }
    case 'HANDLE_SHOW_HIDE_REPLY':
      if (action.rId) {
        state.activity_feed_reply[action.aId][action.rId].open = action.show
      } else {
        state.activity_feed_reply[action.aId].open = action.show
      }
      return state
    case 'UPDATED_ORDER':
      state.current_order = {
        ...state.current_order,
        ...action.current_order,
      }
      return {
        ...state,
        current_order: {
          ...state.current_order,
          ...action.current_order,
        },
        tracking: action.tracking,
        close_forms: true,
      }
    case 'CANCELLED_ORDER':
      return {
        ...state,
        current_order: {
          ...state.current_order,
          status: 'cancelled',
        },
      }
    case 'UPDATED_MULTIPLE_ORDERS':
      return {
        ...state,
        order: state.order.map((o) => {
          return {
            ...o,
            ...action.order.find((x) => x && x.id === o.id),
          }
        }),
      }
    case 'ADDED_ORDER_FILE':
      action.file.created_by = {
        first_name: userInfo.user.first_name,
        last_name: userInfo.user.last_name,
      }
      state.current_order.order_files.unshift(action.file)
      state.close_forms = true
      return state
    case 'ADDED_ORDER_ACTIVITY':
      const userInfoActivity = JSON.parse(
        atob(localStorage.getItem('token').split('.')[1])
      )
      state.current_order.order_activities.unshift({
        ...action.activity,
        order_activity_replies: [],
        user: {
          first_name: userInfoActivity.user.first_name,
          last_name: userInfoActivity.user.last_name,
        },
      })
      state.activity_feed_reply[action.activity.id] = { open: false }
      return state
    case 'ADDED_ORDER_REPLY':
      const userInfoReply = JSON.parse(
        atob(localStorage.getItem('token').split('.')[1])
      )
      for (let i = 0; i < state.current_order.order_activities.length; i++) {
        if (state.current_order.order_activities[i].id === action.parent_id) {
          state.current_order.order_activities[i].order_activity_replies.push({
            ...action.activity,
            user: {
              first_name: userInfoReply.user.first_name,
              last_name: userInfoReply.user.last_name,
            },
          })
        }
      }
      state.activity_feed_reply[action.parent_id][action.activity.id] = {
        open: false,
      }
      return state
    case 'ORDER_ADD_SHIPMENT':
      let shipments = state.current_order.shipments
      for (let shipment of shipments) {
        if (shipment?.id === action.shipment_id) {
          shipment.pick_up_address_id = action.address.id
          shipment.pick_up_address = action.address.id
        }
      }
      return {
        ...state,
        current_order: {
          ...state.current_order,
          shipments: shipments,
          shipping_from_address_id: action.address.id,
          shipping_from_address: action.address,
        },
      }
    case 'ORDER_ADD_PARCEL':
      let shipmentsArr
      if (action.shippingMethod === 'myself') shipmentsArr = action.shipments
      else if (action.shippingMethod === 'flexlane')
        shipmentsArr = [action.shipments]
      return {
        ...state,
        current_order: {
          ...state.current_order,
          status: 'packed',
          shipments: shipmentsArr,
        },
      }
    case 'ORDER_EDIT_PARCEL':
      return {
        ...state,
        current_order: {
          ...state.current_order,
          status: 'packed',
          shipments: action.shipments,
        },
      }
    case 'ORDER_RESET_PARCEL':
      return {
        ...state,
        current_order: {
          ...state.current_order,
          status: 'confirmed',
          shipments: null,
        },
      }
    case 'GET_SHIPMENT_PRICE':
      return {
        ...state,
        current_order: {
          ...state.current_order,
          shipperDetails: action.result,
        },
        postmen_errors: action.errorMessages,
      }
    case 'RESET_CURRENT_ORDER':
      return {
        ...state,
        current_order: {},
      }
    case 'LIST_PRODUCTS_BROWSE':
      return {
        ...state,
        manual_order: {
          ...state.manual_order,
          browseProducts: action.result,
        },
      }
    case 'LIST_CUSTOMERS':
      return {
        ...state,
        manual_order: {
          ...state.manual_order,
          customers: action.result,
        },
      }
    case 'CREATE_NEW_CUSTOMER':
      return {
        ...state,
        manual_order: {
          ...state.manual_order,
          retailer_id: action.result,
        },
      }
    case 'MANUAL_ORDER_CREATE':
      return {
        ...state,
        manual_order: {
          ...state.manual_order,
          createdOrderId: action.result,
        },
      }
    case 'INSERTED_CREDIT_CARD':
      return {
        ...state,
        manual_order: {
          ...state.manual_order,
          last4: action.last4,
        },
      }
    case 'GET_MANUAL_ORDER_DRAFT':
      return {
        ...state,
        manual_order_draft: action.result,
      }
    case 'SAVE_MANUAL_ORDER_DRAFT':
      return {
        ...state,
        manual_order_draft: action.result,
      }
    case 'USER_LOOKUP':
      return {
        ...state,
        manual_order: {
          ...state.manual_order,
          existingUser: action.result,
        },
      }
    case 'SET_STATUS_SHIPPED':
      return {
        ...state,
        current_order: {
          ...state.current_order,
          status: 'shipped',
        },
      }
    case 'ADD_CUSTOM_PRODUCT':
      let browseProductsTemp = [...state.manual_order?.browseProducts]
      browseProductsTemp.unshift(action.result)
      return {
        ...state,
        manual_order: {
          ...state.manual_order,
          browseProducts: browseProductsTemp,
        },
      }
    case 'DISPUTE_LIST':
      return {
        ...state,
        disputes: action.result,
      }
    case 'DISPUTE_APPROVED':
      for (let dispute of state.disputes) {
        if (dispute.id === action.result) {
          dispute.status = 'approved'
        }
      }
      return {
        ...state,
      }
    case 'DISPUTE_REJECTED':
      for (let dispute of state.disputes) {
        if (dispute.id === action.result) {
          dispute.status = 'rejected'
        }
      }
      return {
        ...state,
      }
    case 'DISPUTE_RESOLVED':
      for (let dispute of state.disputes) {
        if (dispute.id === action.result) {
          dispute.status = 'brand-resolved'
        }
      }
      return {
        ...state,
      }
    default:
      return state
  }
}
