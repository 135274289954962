import axios from 'axios'
import config from '../config/config'

export const httpService = {
  get,
  post,
  put,
  del,
}

/** @type {Array} */
let requestsToRefresh = []

/** @type {boolean} */
let isRefreshRequesting = false

axios.interceptors.response.use(
  (response) => response,
  (err) => {
    const { response } = err

    if (response && response !== undefined && response.status === 401) {
      // If we have not logged in before, it makes no sense
      // to try to get a new token
      if (!localStorage.getItem('token')) {
        return Promise.reject(err)
      }

      // User is auth, probably token is expired, try renew
      // And send all failed requests again
      if (!isRefreshRequesting) {
        isRefreshRequesting = true

        console.log('Request for a new token, ' + config.baseUrl)

        // Send request to refresh token
        fetch(config.baseUrl + '/token/refresh', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            Device: 'device',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
          redirect: 'follow',
          referrer: 'no-referrer',
          body: JSON.stringify({
            refresh_token: localStorage.getItem('refresh_token'),
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            localStorage.setItem('token', res.token)
            localStorage.setItem('refresh_token', res.refresh_token)
            localStorage.setItem(
              'refresh_token_exp',
              Date.parse(res.refresh_token_exp).toString()
            )
            requestsToRefresh.forEach((cb) => cb(res.token))
          })
          .catch((e) => {
            // If you have a closed system, you can also
            // redirect/router to the login page
            console.log('An error occurred while getting a new token')
            localStorage.removeItem('token')
            requestsToRefresh.forEach((cb) => cb(null))
          })
          .finally(() => {
            console.log('Clear queue of failed requests')
            requestsToRefresh = []
            isRefreshRequesting = false
          })
      }

      console.log(
        `The request is waiting for a new token.. [${response.config.url}]`
      )
      return new Promise((resolve, reject) => {
        // In our variable (requests that expect a new token
        // from the first request), we add a callback,
        // which the first request to execute
        requestsToRefresh.push((token) => {
          if (token) {
            response.config.headers.Authorization = 'Bearer ' + token
            resolve(axios(response.config))
          } else {
            // If the first request could not update the token, we
            // must return the basic request processing logic
            reject(err)
          }
        })
      })
    }

    return Promise.reject(err)
  }
)

function get(apiEndpoint, responseType = {}, params = {}) {
  const header = { Authorization: 'Bearer ' + localStorage.getItem('token') }
  return axios
    .get(config.baseUrl + apiEndpoint, {
      headers: header,
      responseType: responseType,
      params: params,
    })
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.log(err)
    })
}

function post(apiEndpoint, payload) {
  const header = { Authorization: 'Bearer ' + localStorage.getItem('token') }
  return axios
    .post(config.baseUrl + apiEndpoint, payload, { headers: header })
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.log(err)
    })
}
function put(apiEndpoint, payload) {
  const header = { Authorization: 'Bearer ' + localStorage.getItem('token') }
  return axios
    .put(config.baseUrl + apiEndpoint, payload, { headers: header })
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.log(err)
    })
}
function del(apiEndpoint, payload = {}) {
  const header = { Authorization: 'Bearer ' + localStorage.getItem('token') }
  return axios
    .delete(config.baseUrl + apiEndpoint, { headers: header, data: payload })
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.log(err)
    })
}
