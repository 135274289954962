const initialState = { 
    material: [],
    supplier: [],
    id: '',
    name: '',
    description: '',
    category: '',
    createdAt: '',
    updatedAt: ''
};
export function material(state = initialState, action) {
    switch (action.type) {
        case 'LIST_ALL':
            return {
                ...state,
                material: action.material,
                supplier: action.supplier
            };
        case 'GET_DETAILS':
            return {
                ...state,
                id: action.id,
                name: action.name,
                description: action.description
            };
        case "UPDATED":
            return state;
        case "HANDLE_ON_CHANGE":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state
     }
}
