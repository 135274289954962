const initialState = {
  currency_id: localStorage.getItem('currency')
    ? parseInt(localStorage.getItem('currency') as string)
    : null,
  currencies: [],
  brand_currency: {},
}
export function currency(state = initialState, action) {
  switch (action.type) {
    case 'CURRENCY/SET_CURRENCY':
      return {
        ...state,
        currency_id: action.currency_id,
      }
    case 'CURRENCY/GET_CURRENCY':
      return {
        ...state,
        currencies: action.result,
      }
    case 'CURRENCY/GET_BRAND_CURRENCY':
      return {
        ...state,
        brand_currency: action.result,
      }
    default:
      return state
  }
}
