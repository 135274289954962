import { loginAction } from '../actions'
import {
  crypt,
  history,
  httpService,
  Segment,
  SegmentTrackEvent,
} from '../helpers'

export const orderAction = {
  resetOrder,
  getOrder,
  getOrderById,
  editOrder,
  editMultipleOrders,
  cancelOrder,
  addOrderFile,
  getOrderFile,
  addOrderActivity,
  handleShowHideReply,
  addShipmentAddress,
  addShipmentParcel,
  editShipmentParcel,
  resetShipmentParcel,
  acceptOrder,
  submitShippingEstimate,
  submitEditShippingEstimate,
  resetCurrentOrder,
  listProductsBrowse,
  listCustomers,
  createNewCustomer,
  createManualOrder,
  addCreditCard,
  getManualOrderDraft,
  editManualOrder,
  editManualOrderDraft,
  editOrderAvailability,
  userLookup,
  setStatusShipped,
  deleteOrderFile,
  getOrderForEdit,
  addCustomProduct,
  getDisputeList,
  acceptDispute,
  rejectDispute,
  resolveDispute,
}
function resetOrder() {
  return (dispatch) => {
    dispatch({ type: 'RESET_ORDER' })
  }
}
function getOrder(params) {
  return (dispatch) => {
    let apiEndpoint = '/order'
    httpService
      .get(apiEndpoint, {}, params)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'LIST_ORDERS',
            order: response.data.order.rows,
            count: response.data.order.count,
            company: response.data.company,
            isFilter: params.isFilter,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getOrderById(id) {
  return (dispatch) => {
    let apiEndpoint = '/order/' + id
    httpService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'GET_ORDER_DETAILS',
            current_order: response.data.current_order,
            shipping: response.data.shipping,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getOrderForEdit(id) {
  return (dispatch) => {
    let apiEndpoint = '/order/edit/' + id
    httpService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'GET_ORDER_FOR_EDIT',
            current_order: response.data.current_order,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function editOrder(id, payload, setErrors) {
  return (dispatch) => {
    let apiEndpoint = '/order/' + id
    httpService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'UPDATED_ORDER',
            current_order: response.data.current_order,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        } else {
          setErrors({
            tracking_number: false,
            shipping_cost: false,
            aftership: true,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function acceptOrder(id, payload, setErrors, onSuccess) {
  const userInfo = crypt.getUserInfo()
  return (dispatch) => {
    Segment.track(SegmentTrackEvent.ORDER_ACCEPTED, {
      brand_id: userInfo.company.id,
      brand_name: userInfo.company.name,
      retailer_id: payload.buying_company_id,
    })
    let apiEndpoint = '/order/' + id + '/accept/'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          onSuccess()
          dispatch({
            type: 'UPDATED_ORDER',
            current_order: response.data.current_order,
          })
          dispatch({
            type: 'REMOVE_NEW_ORDER_NOTIFICATION',
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        } else {
          setErrors({
            tracking_number: false,
            shipping_cost: false,
            aftership: true,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function submitShippingEstimate(id, payload) {
  const userInfo = crypt.getUserInfo()
  return (dispatch) => {
    Segment.track(SegmentTrackEvent.ORDER_ESTIMATED_SHIPPING_DATE_ENTERED, {
      brand_id: userInfo.company.id,
      brand_name: userInfo.company.name,
      estimated_shipping_duration: Math.round(
        (payload.target_shipment_date - Date.now()) / (1000 * 60 * 60 * 24)
      ),
    })
    let apiEndpoint = '/order/' + id + '/shipping-estimate/'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        console.log(response.data)
        if (response.data.success) {
          dispatch({
            type: 'UPDATED_ORDER',
            current_order: response.data.current_order,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function submitEditShippingEstimate(id, payload) {
  const userInfo = crypt.getUserInfo()
  return (dispatch) => {
    Segment.track(SegmentTrackEvent.ORDER_ESTIMATED_SHIPPING_DATE_ENTERED, {
      brand_id: userInfo.company.id,
      brand_name: userInfo.company.name,
      estimated_shipping_duration: Math.round(
        (payload.target_shipment_date - Date.now()) / (1000 * 60 * 60 * 24)
      ),
    })
    let apiEndpoint = '/order/' + id + '/edit-shipping-estimate/'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        console.log(response.data)
        if (response.data.success) {
          dispatch({
            type: 'UPDATED_ORDER',
            current_order: response.data.current_order,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function editMultipleOrders(payload) {
  return (dispatch) => {
    let apiEndpoint = '/order'
    httpService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'UPDATED_MULTIPLE_ORDERS',
            order: response.data.result,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function cancelOrder(id, payload) {
  const userInfo = crypt.getUserInfo()
  return (dispatch) => {
    Segment.track(SegmentTrackEvent.ORDER_CANCELLED, {
      brand_id: userInfo.company.id,
      brand_name: userInfo.company.name,
      retailer_id: payload.buying_company_id,
    })
    let apiEndpoint = '/order/' + id + '/cancel'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'CANCELLED_ORDER',
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function addOrderFile(id, payload) {
  return (dispatch) => {
    let apiEndpoint = '/order/' + id + '/file/'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'ADDED_ORDER_FILE',
            file: response.data.result,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function deleteOrderFile(id, payload) {
  return (dispatch) => {
    let apiEndpoint = '/order/file/' + id
    httpService
      .del(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'DELETED_ORDER_FILE',
            id: id,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function getOrderFile(id) {
  return (dispatch) => {
    let apiEndpoint = '/order/file/' + id
    httpService
      .get(apiEndpoint, 'blob')
      .then((response) => {
        const file = new Blob([response.data], { type: response.data.type })
        const url = URL.createObjectURL(file)
        window.open(url)
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function addOrderActivity(id, formData) {
  return (dispatch) => {
    let apiEndpoint = '/order/' + id + '/activity/'
    httpService
      .post(apiEndpoint, formData)
      .then((response) => {
        if (formData.get('parent_activity_id') === 'null') {
          dispatch({
            type: 'ADDED_ORDER_ACTIVITY',
            activity: response.data.result,
          })
        } else {
          dispatch({
            type: 'ADDED_ORDER_REPLY',
            activity: response.data.result,
            parent_id: formData.get('parent_activity_id'),
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function handleShowHideReply(aId, rId, show) {
  return (dispatch) => {
    dispatch({
      type: 'HANDLE_SHOW_HIDE_REPLY',
      aId: aId,
      rId: rId,
      show: show,
    })
  }
}
function addShipmentAddress(id, payload) {
  return (dispatch) => {
    let apiEndpoint = '/order/' + id + '/shipment-address/'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            dispatch({
              type: 'ORDER_ADD_SHIPMENT',
              address: response.data.result.address,
              shipment_id: response.data.result.shipment_id,
            })
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function addShipmentParcel(id, payload, onSuccess) {
  const userInfo = crypt.getUserInfo()
  return (dispatch) => {
    Segment.track(SegmentTrackEvent.ORDER_SHIPMENT_PARCEL_ADDED, {
      brand_id: userInfo?.company?.id,
      brand_name: userInfo?.company?.name,
      shipping_method: payload.method,
    })
    let apiEndpoint = '/order/' + id + '/shipment-parcel/'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            onSuccess()
            dispatch({
              type: 'ORDER_ADD_PARCEL',
              shippingMethod: payload.method,
              shipments: response.data.result,
            })

            if (!response.data.labelCreated) {
              Segment.track(
                SegmentTrackEvent.ORDER_SHIPMENT_FAILED_TO_CREATE_SHIPPING_LABEL,
                {
                  brand_id: userInfo?.company?.id,
                  brand_name: userInfo?.company?.name,
                  shipping_method: payload.method,
                }
              )
            }
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function editShipmentParcel(id, payload, onSuccess) {
  const userInfo = crypt.getUserInfo()
  return (dispatch) => {
    // Segment.track(SegmentTrackEvent.ORDER_SHIPMENT_PARCEL_ADDED, {
    //   brand_id: userInfo?.company?.id,
    //   brand_name: userInfo?.company?.name,
    //   shipping_method: payload.method,
    // })
    let apiEndpoint = '/order/' + id + '/edit-shipment-parcel/'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            onSuccess()
            dispatch({
              type: 'ORDER_EDIT_PARCEL',
              shippingMethod: payload.method,
              shipments: response.data.result,
            })

            // if (!response.data.labelCreated) {
            //   Segment.track(
            //     SegmentTrackEvent.ORDER_SHIPMENT_FAILED_TO_CREATE_SHIPPING_LABEL,
            //     {
            //       brand_id: userInfo?.company?.id,
            //       brand_name: userInfo?.company?.name,
            //       shipping_method: payload.method,
            //     }
            //   )
            // }
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function resetShipmentParcel(id, payload) {
  return (dispatch) => {
    let apiEndpoint = '/order/' + id + '/reset-shipment-parcel/'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            console.log("anbnp test1")
            dispatch({
              type: 'ORDER_RESET_PARCEL',
              shippingMethod: payload.method,
              shipments: [],
            })
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getShipmentPrice(id, payload) {
  return (dispatch) => {
    let apiEndpoint = '/order/' + id + '/shipment-price/'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            dispatch({
              type: 'GET_SHIPMENT_PRICE',
              result: response.data.result,
              errorMessages: response.data.errorMessages,
            })
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function resetCurrentOrder() {
  return (dispatch) => {
    dispatch({
      type: 'RESET_CURRENT_ORDER',
    })
  }
}
function listProductsBrowse() {
  return (dispatch) => {
    let apiEndpoint = '/order/new/products/'
    httpService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            dispatch({
              type: 'LIST_PRODUCTS_BROWSE',
              result: response.data.result,
            })
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function listCustomers() {
  return (dispatch) => {
    let apiEndpoint = '/order/new/customers'
    httpService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            dispatch({
              type: 'LIST_CUSTOMERS',
              result: response.data.result,
            })
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createNewCustomer(payload, onSuccess) {
  return (dispatch) => {
    let apiEndpoint = '/order/new/create-customer'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            dispatch({
              type: 'CREATE_NEW_CUSTOMER',
              result: response.data.result,
            })
            onSuccess()
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createManualOrder(payload, onSuccess, saveResult) {
  return (dispatch) => {
    let apiEndpoint = '/order/new/create-order'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            if (response.data.message) {
              saveResult({
                open: true,
                result: 'warning',
                disabled: false,
                message: response.data.message,
              })
              setTimeout(() => {
                history.push('/order')
              }, 3000)
            } else if (!payload.saveDraft) {
              onSuccess(response.data.result)
            } else {
              saveResult({
                open: true,
                result: 'success',
                disabled: false,
              })
              setTimeout(() => {
                history.push('/order')
              }, 1000)
            }
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          } else {
            saveResult({
              open: true,
              result: 'error',
              disabled: false,
            })
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function addCreditCard(payload, onSuccess) {
  return (dispatch) => {
    let apiEndpoint = '/order/new/add-credit-card'
    httpService.post(apiEndpoint, payload).then((response) => {
      if (response.data.success) {
        let paymentMethod = response.data.result
        onSuccess()
        dispatch({
          type: 'INSERTED_CREDIT_CARD',
          last4: paymentMethod.card.last4,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
  }
}

function getManualOrderDraft(id) {
  return (dispatch) => {
    let apiEndpoint = '/order/new/' + id
    httpService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            console.log(response)
            dispatch({
              type: 'GET_MANUAL_ORDER_DRAFT',
              result: response.data.result,
            })
            console.log(response.data.result)
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function editManualOrder(id, payload, onSuccess, saveResult) {
  return (dispatch) => {
    let apiEndpoint = '/order/new/' + id
    httpService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            saveResult({
              open: true,
              result: 'success',
              disabled: false,
            })
            onSuccess()
            console.log(response.data.result)
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          } else {
            saveResult({
              open: true,
              result: 'error',
              disabled: false,
            })
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function editManualOrderDraft(id, payload, onSuccess, saveResult) {
  return (dispatch) => {
    let apiEndpoint = '/order/draft/' + id
    httpService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            saveResult({
              open: true,
              result: 'success',
              disabled: false,
            })
            onSuccess()
            console.log(response.data.result)
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          } else {
            saveResult({
              open: true,
              result: 'error',
              disabled: false,
            })
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function editOrderAvailability(id, payload, setShowOrderUpdated) {
  const userInfo = crypt.getUserInfo()
  Segment.track(SegmentTrackEvent.ORDER_AVAILABILITY_EDITED, {
    brand_id: userInfo.company.id,
    brand_name: userInfo.company.name,
  })
  return (dispatch) => {
    let apiEndpoint = '/order/update/' + id
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            setShowOrderUpdated(true)
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function userLookup(payload) {
  return (dispatch) => {
    let apiEndpoint = '/order/new/user-lookup'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        console.log(response.data)
        if (response.data) {
          if (response.data.success) {
            dispatch({
              type: 'USER_LOOKUP',
              result: response.data.result,
            })
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function setStatusShipped(id) {
  return (dispatch) => {
    let apiEndpoint = '/order/update/set-shipped/' + id
    httpService
      .post(apiEndpoint)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            dispatch({
              type: 'SET_STATUS_SHIPPED',
            })
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function addCustomProduct(payload, onSuccess) {
  return (dispatch) => {
    let apiEndpoint = '/order/new/custom'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            dispatch({
              type: 'ADD_CUSTOM_PRODUCT',
              result: response.data.result,
            })
            onSuccess(response.data.result, parseInt(payload.quantity))
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getDisputeList(id, payload) {
  return (dispatch) => {
    let apiEndpoint = '/order/dispute/' + id
    httpService
      .get(apiEndpoint, payload)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            dispatch({
              type: 'DISPUTE_LIST',
              result: response.data?.result?.reports,
            })
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function acceptDispute(payload) {
  return (dispatch) => {
    let apiEndpoint = '/order/approve-dispute'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            dispatch({
              type: 'DISPUTE_APPROVED',
              result: payload?.id,
            })
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function rejectDispute(payload) {
  return (dispatch) => {
    let apiEndpoint = '/order/reject-dispute'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            dispatch({
              type: 'DISPUTE_REJECTED',
              result: payload?.id,
            })
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function resolveDispute(payload) {
  return (dispatch) => {
    let apiEndpoint = '/order/brand-resolve-dispute'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            dispatch({
              type: 'DISPUTE_RESOLVED',
              result: payload?.id,
            })
          } else if (response.data.logout) {
            dispatch(loginAction.logout())
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
