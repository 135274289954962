import { httpService, history } from '../helpers/'
import { loginAction } from '../actions'

const getProductsAndRegions = () => (dispatch) => {
  const apiEndpoint = '/promotion/new'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'PROMOTION/LIST_PRODUCTS',
          products: response.data.products,
          regions: response.data.regions,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const createPromotion = (payload) => (dispatch) => {
  const apiEndpoint = '/promotion/new'
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        if (payload.promotion_type === 'storewide')
          history.push({
            pathname: '/promotion/' + response.data.promotion_id,
            state: { new: true },
          })
        else history.push('/promotion')
        dispatch({
          type: 'PROMOTION/CREATE_PROMOTION',
          // result: response.data.result,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const editPromotion = (id, payload) => (dispatch) => {
  const apiEndpoint = '/promotion/edit/' + id
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        history.push({
          pathname: '/promotion/' + response.data.promotion_id,
          state: { edit: true },
        })
        dispatch({
          type: 'PROMOTION/EDIT_PROMOTION',
          // result: response.data.result,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const listPromotions = () => (dispatch) => {
  const apiEndpoint = '/promotion'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'PROMOTION/LIST_PROMOTIONS',
          result: response.data.result,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const getPromotionById = (id) => (dispatch) => {
  const apiEndpoint = '/promotion/' + id
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'PROMOTION/GET_PROMOTION_BY_ID',
          result: response.data.result,
          redeemed: response.data.redeemed,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const enablePromotion = (id, setSaveResult) => (dispatch) => {
  const apiEndpoint = '/promotion/' + id
  httpService
    .post(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'PROMOTION/ENABLE_PROMOTION',
        })
        setSaveResult({
          open: true,
          result: 'success',
          disabled: false,
          message: 'Your promotion has been enabled',
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      setSaveResult({
        open: true,
        result: 'error',
        disabled: false,
        message: '',
      })
      console.log(err)
    })
}

const disablePromotion = (id, setSaveResult) => (dispatch) => {
  const apiEndpoint = '/promotion/' + id
  httpService
    .put(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'PROMOTION/DISABLE_PROMOTION',
        })
        setSaveResult({
          open: true,
          result: 'success',
          disabled: false,
          message: 'Your promotion has been disabled',
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      setSaveResult({
        open: true,
        result: 'error',
        disabled: false,
        message: '',
      })
      console.log(err)
    })
}

const deletePromotion = (id) => (dispatch) => {
  const apiEndpoint = '/promotion/edit/' + id
  httpService
    .put(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        history.push('/promotion')
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const promotionAction = {
  getProductsAndRegions,
  createPromotion,
  listPromotions,
  getPromotionById,
  disablePromotion,
  enablePromotion,
  editPromotion,
  deletePromotion,
}
