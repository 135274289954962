const initialState = {
  products: [],
  orders: [],
  messages: [],
  company: {},
  collectionExists: false,
  directTotals: {},
  categories: [],
  slugExists: false,
  newMessagesCount: 0,
  newOrdersCount: 0,
  is_peeba_direct: false,
}
export function home(state = initialState, action) {
  switch (action.type) {
    case 'GET_HOME_INFO':
      return {
        ...state,
        products: action.products,
        orders: action.orders,
        messages: action.messages,
        collectionExists: action.collectionExists,
      }
    case 'GET_COMPANY_INFO':
      return {
        ...state,
        company: action.company,
        directTotals: action.directTotals,
      }
    case 'GET_FIRST_LOGIN_CATEGORIES':
      return {
        ...state,
        categories: action.result,
      }
    case 'CHECK_SLUG':
      return {
        ...state,
        slugExists: action.slugExists,
      }
    case 'NEW_NOTIFICATIONS':
      return {
        ...state,
        newMessagesCount: action.newMessages,
        newOrdersCount: action.newOrders,
        is_peeba_direct:
          action.brand.peeba_direct_status === 'verified' ? true : false,
      }
    case 'REMOVE_NEW_MESSAGE_NOTIFICATION':
      if (state.newMessagesCount > 0) {
        return {
          ...state,
          newMessagesCount: state.newMessagesCount - 1,
        }
      } else
        return {
          ...state,
        }
    case 'REMOVE_NEW_ORDER_NOTIFICATION':
      if (state.newOrdersCount > 0) {
        return {
          ...state,
          newOrdersCount: state.newOrdersCount - 1,
        }
      } else
        return {
          ...state,
        }
    case 'PEEBA_DIRECT/SUBMIT_WEBSITE':
      return {
        ...state,
        company: {
          ...state.company,
          peeba_direct_status: 'pending',
        },
      }
    default:
      return state
  }
}
