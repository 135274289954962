const initialState = {
  brand: {}
}
export function widgetState(state = initialState, action) {
  switch (action.type) {
    case 'WIDGET/GET_DATA':
      return {
        brand: action.brand
      }
    default:
      return state
  }
}
