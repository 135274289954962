const initialState = {
  current_user: {},
  company: {
    order_notification_email: '',
  },
  close_forms: false,
  error: '',
  banking: undefined,
}
export function settings(state = initialState, action) {
  switch (action.type) {
    case 'SETTINGS/ORDER':
      return {
        ...state,
        company: action.company,
      }
    case 'GET_SETTINGS':
      return {
        ...state,
        current_user: action.current_user,
      }
    case 'USER_CREATED':
      state.close_forms = true
      state.current_user.company.users.unshift(action.new_user)
      return state
    case 'UPDATED_USER':
      const index = state.current_user.company.users.findIndex(
        (x) => x.id === action.current_user.id
      )
      state.current_user.company.users[index] = action.current_user
      return {
        ...state,
        current_user: { ...state.current_user, ...action.current_user },
      }
    case 'DEACTIVATE_ACCOUNT':
      return {
        ...state,
        status: action.status,
      }
    case 'GET_LOCATIONS':
      return {
        ...state,
        locations: action.locations,
      }
    case 'UPDATED_ADDRESS':
      return {
        ...state,
        locations: action.locations,
      }
    case 'INSERTED_ADDRESS':
      return {
        ...state,
        locations: action.locations,
      }
    case 'DEACTIVATE_ADDRESS':
      return {
        ...state,
        locations: action.locations,
      }
    case 'CHANGED_LOGIN':
      state.current_user.username = action.username
      return state
    case 'SENT_CODE':
      return {
        ...state,
        error: action.error,
      }
    case 'SENT_CODE_ERROR':
      return {
        ...state,
        error: action.error,
      }
    case 'SETTINGS/GET_BANKING':
      return {
        ...state,
        banking: action.banking,
      }
    case 'SETTINGS/SET_BANKING':
      return {
        ...state,
        banking: action.banking,
      }
    default:
      return state
  }
}
