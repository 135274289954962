import { httpService, history } from '../helpers/'
import { loginAction } from '../actions'

export const productAction = {
  resetProduct,
  getProduct,
  getProductById,
  getOrdersByProduct,
  editProduct,
  createProduct,
  deleteProduct,
  addVariation,
  editVariation,
  deleteVariation,
  addProductFile,
  editProductFile,
  deleteProductFile,
  getProductFile,
  getCategories,
  getTranslation,
  requestTranslation,
  setAsBestseller,
  removeAsBestseller,
  activateProduct,
  deactivateProduct,
  saveListingOrder,
  addCountryPrice,
  removeCountryPrice,
  editCountryPrice,
  updateTranslations,
}
function resetProduct() {
  return (dispatch) => {
    dispatch({ type: 'RESET_PRODUCT' })
  }
}
function getCategories() {
  return (dispatch) => {
    let apiEndpoint = '/product/category'
    httpService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'GET_CATEGORIES',
            categories: response.data.result,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function getProduct(params) {
  return (dispatch) => {
    let apiEndpoint = '/product'
    httpService
      .get(apiEndpoint, {}, params)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'LIST_PRODUCTS',
            product: response.data.result.rows,
            count: response.data.result.count,
            isFilter: params.isFilter,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function createProduct(payload) {
  return (dispatch) => {
    let apiEndpoint = '/product'
    httpService.post(apiEndpoint, payload).then((response) => {
      if (response.data.success) {
        history.push({
          pathname: '/product/' + response.data.result.id,
          state: { newProduct: true },
        })
        window.scrollTo(0, 0)
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
  }
}
function deleteProduct(id, onSuccess) {
  return (dispatch) => {
    let apiEndpoint = '/product/' + id
    httpService
      .del(apiEndpoint)
      .then((response) => {
        if (response.data.success) {
          onSuccess()
          dispatch({
            type: 'DELETED_PRODUCT',
            id: id,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function getProductById(id) {
  return (dispatch) => {
    let apiEndpoint = '/product/' + id
    httpService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'GET_PRODUCT_DETAILS',
            categories: response.data.categories,
            product: response.data.product,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function getOrdersByProduct(id) {
  return (dispatch) => {
    let apiEndpoint = '/product/orders/' + id
    httpService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'GET_PRODUCT_ORDERS',
            orders: response.data.order,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function editProduct(id, payload, setSaveResult) {
  return (dispatch) => {
    let apiEndpoint = '/product/' + id
    httpService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'EDIT_PRODUCT',
            result: response.data.result,
          })
          setSaveResult({
            open: true,
            result: 'success',
            disabled: false,
            type: 'change',
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        } else {
          setSaveResult({
            open: true,
            result: 'error',
            disabled: false,
            type: 'change',
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function updateTranslations(id, payload, index, onSuccess) {
  return (dispatch) => {
    let apiEndpoint = '/product/' + id + '/translation'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'EDIT_ALL_TRANSLATIONS',
            result: response.data.result,
            index: index,
            payload: payload,
          })
          onSuccess()
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getTranslation(payload) {
  return (dispatch) => {
    let apiEndpoint = '/product/translate'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'GET_TRANSLATION',
            result: response.data.result,
            target: payload.target,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function requestTranslation(payload) {
  return (dispatch) => {
    let apiEndpoint = '/product/request-translation'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function addVariation(id, payload) {
  return (dispatch) => {
    let apiEndpoint = '/product/' + id + '/variation'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'ADDED_VARIATION',
            variation: response.data.result,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function editVariation(id, payload) {
  return (dispatch) => {
    let apiEndpoint = '/product/variation/' + id
    httpService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'EDITED_VARIATION',
            variation: response.data.result.variation,
            material: response.data.result.material,
            size: response.data.result.size,
            color: response.data.result.color,
            style: response.data.result.style,
            version: response.data.result.version,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function deleteVariation(id, payload) {
  return (dispatch) => {
    let apiEndpoint = '/product/variation/' + id
    httpService
      .del(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'DELETED_VARIATION',
            id: id,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function addProductFile(id, payload, onSuccess) {
  return (dispatch) => {
    let apiEndpoint = '/product/' + id + '/file/'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'ADDED_FILE',
            file: response.data.result,
          })
          onSuccess()
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function editProductFile(id, payload) {
  return (dispatch) => {
    let apiEndpoint = '/product/file/' + id
    httpService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'EDITED_FILE',
            file: response.data.result,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function deleteProductFile(id, payload) {
  return (dispatch) => {
    let apiEndpoint = '/product/file/' + id
    httpService
      .del(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'DELETED_FILE',
            id: id,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function getProductFile(id) {
  return (dispatch) => {
    let apiEndpoint = '/product/file/' + id
    httpService
      .get(apiEndpoint, 'blob')
      .then((response) => {
        console.log(response.data)
        const file = new Blob([response.data], { type: response.data.type })
        const url = URL.createObjectURL(file)
        window.open(url)
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function setAsBestseller(id, setSaveResult) {
  return (dispatch) => {
    let apiEndpoint = '/product/set-bestseller/' + id
    httpService
      .post(apiEndpoint)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'MARK_AS_BESTSELLER',
          })
          setSaveResult({
            open: true,
            result: 'success',
            disabled: false,
            message: 'Your product has been set as a bestseller.',
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        } else {
          setSaveResult({
            open: true,
            result: 'error',
            disabled: false,
            message: '',
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function removeAsBestseller(id, setSaveResult) {
  return (dispatch) => {
    let apiEndpoint = '/product/remove-bestseller/' + id
    httpService
      .post(apiEndpoint)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'REMOVE_AS_BESTSELLER',
          })
          setSaveResult({
            open: true,
            result: 'success',
            disabled: false,
            message: 'Your product has been removed as a bestseller.',
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        } else {
          setSaveResult({
            open: true,
            result: 'error',
            disabled: false,
            message: '',
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function activateProduct(id, setSaveResult) {
  return (dispatch) => {
    let apiEndpoint = '/product/activate/' + id
    httpService
      .post(apiEndpoint)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'ACTIVATE_PRODUCT',
          })
          setSaveResult({
            open: true,
            result: 'success',
            disabled: false,
            message: 'You have successfully activated your product.',
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        } else {
          setSaveResult({
            open: true,
            result: 'error',
            disabled: false,
            message: '',
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function deactivateProduct(id, setSaveResult) {
  return (dispatch) => {
    let apiEndpoint = '/product/deactivate/' + id
    httpService
      .post(apiEndpoint)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'DEACTIVATE_PRODUCT',
          })
          setSaveResult({
            open: true,
            result: 'success',
            disabled: false,
            message: 'You have successfully deactivated your product.',
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        } else {
          setSaveResult({
            open: true,
            result: 'error',
            disabled: false,
            message: '',
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function saveListingOrder(map, setSaveResult) {
  return (dispatch) => {
    let apiEndpoint = '/product/listing'
    httpService
      .post(apiEndpoint, map)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'REARRANGE_PRODUCTS',
            result: response.data.result,
          })
          setSaveResult({
            open: true,
            result: 'success',
            disabled: false,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        } else {
          setSaveResult({
            open: true,
            result: 'error',
            disabled: false,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function addCountryPrice(payload, onSuccess) {
  return (dispatch) => {
    let apiEndpoint = '/product/pricebook'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'ADD_COUNTRY_PRICE',
            result: response.data.result,
            payload: payload,
          })
          onSuccess()
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        } else {
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function removeCountryPrice(payload, onSuccess) {
  return (dispatch) => {
    let apiEndpoint = '/product/pricebook/remove'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          onSuccess(payload)
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        } else {
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function editCountryPrice(payload, onSuccess) {
  return (dispatch) => {
    let apiEndpoint = '/product/pricebook/edit'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          onSuccess()
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        } else {
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
