import { history, crypt, httpService } from '../helpers'

export const loginAction = {
  login,
  logout,
  forgotPassword,
  getBrands,
  selectBrand,
  changePassword,
}
function login(username, password, onFailure) {
  return (dispatch) => {
    httpService.get('/login').then((cert) => {
      let payload = {
        username: username,
        password: crypt.hash(password),
      }

      const ephemeralKeyPair = crypt.getEphemKeys()
      const publicKey = cert.data

      httpService
        .post(
          '/login/brand',
          crypt.asymmetricEncrypt(
            ephemeralKeyPair,
            publicKey,
            JSON.stringify(payload)
          )
        )
        .then((response) => {
          if (response) {
            let decrypted = JSON.parse(
              crypt.asymmetricDecrypt(
                ephemeralKeyPair.secretKey,
                publicKey,
                response.data
              )
            )

            localStorage.setItem('token', decrypted.token)
            localStorage.setItem('refresh_token', decrypted.refresh_token)
            localStorage.setItem('refresh_token_exp', Date.parse(decrypted.refresh_token_exp).toString())
            localStorage.setItem('nonce', decrypted.nonce)
            localStorage.setItem('auth', decrypted.auth)
            localStorage.setItem('login', Date.now())
            const userInfo = crypt.parseToken(decrypted.token.split('.')[1])
            localStorage.setItem('language_code', userInfo.user.language_code)

            dispatch(loginUser(response.data))

            if (decrypted.change_password) {
              history.push('/change-password')
            } else {
              if (userInfo?.organization?.id) {
                history.push('/select_brand')
              } else {
                if (decrypted.first_login) {
                  history.push('/home?first_login=true')
                } else history.push('/home')
              }
            }
          } else {
            onFailure()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    })
  }
}
function logout() {
  return (dispatch) => {
    httpService
      .post('/login/logout', { refresh_token: localStorage.getItem('refresh_token') })
      .then((_) => {
        // do nothing
      })
    localStorage.removeItem('auth')
    localStorage.removeItem('nonce')
    localStorage.removeItem('token')
    localStorage.removeItem('refresh_token_exp')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('login')
    dispatch(logoutUser())
    history.push('/')
  }
}

function forgotPassword(payload, setEmailSent, onFailure) {
  return (dispatch) => {
    httpService
      .post('/login/brand/forgotPassword', payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'FORGOT_PASSWORD_EMAIL_SENT',
          })
          setEmailSent(true)
        } else {
          onFailure()
        }
      })
  }
}

function getBrands() {
  return (dispatch) => {
    httpService.get('/login/getBrands').then((response) => {
      console.log(response)
      if (response.data.success) {
        dispatch({
          type: 'ORGANIZATION/GET_BRANDS',
          organization: response.data.result,
        })
      }
    })
  }
}

function selectBrand(id) {
  return (dispatch) => {
    httpService.get('/login/selectBrand/' + id).then((response) => {
      console.log(response)
      if (response.data.success) {
        localStorage.setItem('token', response.data.result)
        history.push('/home')
      }
    })
  }
}

function changePassword(payload, setSaveResult) {
  return (dispatch) => {
    httpService.post('/login/change-password', payload).then((response) => {
      if (response.data.success) {
        let userInfo = crypt.getUserInfo()

        if (userInfo.organization?.id) history.push('/select_brand')
        else {
          if (response.data.first_login) {
            history.push('/home?first_login=true')
          } else history.push('/home')
        }
      } else {
        setSaveResult({
          open: true,
          result: 'error',
          disabled: false,
          type: 'change',
        })
      }
    })
  }
}

export function loginUser(user) {
  return {
    type: 'LOGIN_SUCCESS',
    auth: user.auth,
    nonce: user.nonce,
  }
}
export function logoutUser() {
  return {
    type: 'LOGOUT_SUCCESS',
    auth: false,
    nonce: '',
  }
}
