const initialState = {
  order_volume: 0.0,
  total_orders: 0,
  total_clicks: 0,
  monthly_orders: [],
  monthly_orders_count: 0,
  monthly_clicks: [],
  monthly_clicks_count: 0,
  monthly_customer_count: 0,
  monthly_referral_count: 0,
  monthly_marketplace_count: 0,
  monthly_sell_through: 0,
  monthly_returns_count: 0,
  monthly_rating: 0,
  monthly_reviews_count: 0,
  monthly_5_star_reviews_count: 0,
}
export function dashboard(state = initialState, action) {
  switch (action.type) {
    case 'DASHBOARD/ORDER_TOTALS':
      return {
        ...state,
        order_volume: action.order_volume,
        total_orders: action.total_orders,
      }
    case 'DASHBOARD/CLICK_TOTALS':
      return {
        ...state,
        total_clicks: action.total_clicks,
      }
    case 'DASHBOARD/MONTHLY_ORDERS':
      return {
        ...state,
        monthly_orders: action.monthly_orders,
        monthly_orders_count: action.monthly_orders_count,
      }
    case 'DASHBOARD/MONTHLY_CLICKS':
      return {
        ...state,
        monthly_clicks: action.monthly_clicks,
        monthly_clicks_count: action.monthly_clicks_count,
        monthly_customer_count: action.monthly_customer_count,
        monthly_referral_count: action.monthly_referral_count,
        monthly_marketplace_count: action.monthly_marketplace_count,
      }
    case 'DASHBOARD/MONTHLY_SELL_THROUGH':
      return {
        ...state,
        monthly_sell_through: action.monthly_sell_through,
        monthly_returns_count: action.monthly_returns_count,
      }
    case 'DASHBOARD/MONTHLY_REVIEWS':
      return {
        ...state,
        monthly_rating: action.monthly_rating,
        monthly_reviews_count: action.monthly_reviews_count,
        monthly_5_star_reviews_count: action.monthly_5_star_reviews_count,
      }
    default:
      return state
  }
}
