import { combineReducers } from 'redux'
import { authentication } from './auth'
import { settings } from './settings'
import { store } from './store'
import { order } from './order'
import { organization } from './organization'
import { product } from './product'
import { brand_list } from './brand_list'
import { material } from './material'
import { network } from './network'
import { brand } from './preview'
import { chat } from './chat'
import { balance } from './balance'
import { dashboard } from './dashboard'
import { sell_through } from './sell_through'
import { review } from './review'
import { promotion } from './promotion'
import { home } from './home'
import { currency } from './currency'
import { superbrand } from './superbrand'
import { widgetState } from './widget'
import { payoutState } from './payout'
import { productCollectionState } from './product_collection'
import { bankingState } from './banking'

const rootReducer = combineReducers({
  authentication,
  settings,
  store,
  order,
  organization,
  product,
  material,
  network,
  brand,
  brand_list,
  chat,
  balance,
  dashboard,
  sell_through,
  review,
  promotion,
  home,
  currency,
  superbrand,
  widgetState,
  payoutState,
  productCollectionState,
  bankingState,
})

export default rootReducer
