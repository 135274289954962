const initialState = {
  banking_fields: [],
  banking_information: null,
}

export function bankingState(state = initialState, action) {
  switch (action.type) {
    case 'BANKING/GET_BANKING_INFO':
      return {
        ...state,
        banking_information: action.banking_information,
      }
    case 'BANKING/GET_FIELDS':
      return {
        ...state,
        banking_fields: action.banking_schema.fields,
      }
    default:
      return state
  }
}
