import { history, httpService } from '../helpers'
import { loginAction } from '../actions'

export const networkAction = {
  resetNetwork,
  getNetworkCount,
  getNetwork,
  getNetworkById,
  onChangeProps,
  editNetwork,
  createNetwork,
  userLookup,
  deleteNetwork,
  inviteRetailer,
  editNetworkNote,
  getNetworkInfo,
  getNetworkOrders,
  sendInvite,
  extendCoupon,
}
function resetNetwork() {
  return (dispatch) => {
    dispatch({ type: 'RESET_NETWORK' })
  }
}
function getNetwork(params) {
  return (dispatch) => {
    let apiEndpoint = '/network'
    if (params.filter === 'uncontacted') {
      apiEndpoint = '/network/uncontacted'
    } else if (params.filter === 'contacted') {
      apiEndpoint = '/network/contacted'
    } else if (params.filter === 'signed_up') {
      apiEndpoint = '/network/signed-up'
    } else if (params.filter === 'ordered') {
      apiEndpoint = '/network/ordered'
    }
    httpService
      .get(apiEndpoint, {}, params)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'LIST_NETWORKS',
            network: response.data.network,
            count: response.data.count,
            company: response.data.company,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function getNetworkCount(params) {
  return (dispatch) => {
    let apiEndpoint = '/network/count'
    httpService
      .get(apiEndpoint, {}, params)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'COUNT_NETWORKS',
            count: response.data.count,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function userLookup(params) {
  return (dispatch) => {
    let apiEndpoint = '/network/user-lookup'
    httpService
      .get(apiEndpoint, {}, params)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'USER_LOOKUP',
            existingUser: response.data.result,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function createNetwork(payload) {
  return (dispatch) => {
    let apiEndpoint = '/network'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch(createdNetwork())
          history.push('/network/' + response.data.result.id)
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getNetworkById(id) {
  return (dispatch) => {
    let apiEndpoint = '/network/' + id
    httpService.get(apiEndpoint).then((response) => {
      if (response.data.success) {
        dispatch({
          type: 'GET_DETAILS',
          current_network: response.data.result,
          chat: response.data.chat,
          orders: response.data.orders,
          network: response.data.network,
          cartValues: response.data.cartValues,
          directTotals: response.data.directTotals,
          brand: response.data.brand,
          activity: response.data.activity,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
  }
}

function getNetworkInfo(id) {
  return (dispatch) => {
    let apiEndpoint = '/network/info/' + id
    httpService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'GET_NETWORK_INFO',
            result: response.data.result,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getNetworkOrders(id) {
  return (dispatch) => {
    let apiEndpoint = '/network/order/' + id
    httpService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'GET_NETWORK_ORDERS',
            result: response.data.result,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function editNetworkNote(payload, onSuccess) {
  return (dispatch) => {
    let apiEndpoint = '/network/' + payload.id
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'EDIT_NOTE',
            note: payload.text,
          })
          onSuccess()
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function sendInvite(payload, onSuccess) {
  return (dispatch) => {
    let apiEndpoint = '/network/invite'
    httpService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'SEND_INVITE',
          })
          onSuccess()
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    dispatch(handleOnChangeProps(props, event.target.value))
  }
}

function editNetwork(id, payload) {
  return (dispatch) => {
    let apiEndpoint = '/network/' + id
    httpService.put(apiEndpoint, payload).then((response) => {
      dispatch(updatedNetwork())
      history.push('/network')
    })
  }
}

function deleteNetwork(id) {
  return (dispatch) => {
    let apiEndpoint = '/network/' + id
    httpService.del(apiEndpoint).then((response) => {
      dispatch(deletedNetwork())
      dispatch(networkAction.getNetwork())
    })
  }
}

function inviteRetailer(payload, onFailure) {
  return (dispatch) => {
    let apiEndpoint = '/network/invite'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          history.push('/network/' + response.data.result.id)
        } else if (response.data.error) {
          onFailure(response.data.error)
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function extendCoupon(payload, setSaveResult) {
  return (dispatch) => {
    let apiEndpoint = '/network/extend-coupon'
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'EXTEND_COUPON_DATE',
            coupon_id: payload.coupon_id,
          })
          setSaveResult({
            open: true,
            result: 'success',
            disabled: false,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        } else {
          setSaveResult({
            open: true,
            result: 'error',
            disabled: false,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export function changeNetworksList(network) {
  return {
    type: 'LIST_NETWORKS',
    network: network.rows,
    count: network.count,
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'HANDLE_ON_CHANGE',
    props: props,
    value: value,
  }
}
export function editedNetwork(data) {
  return {
    type: 'GET_DETAILS',
    current_network: data,
  }
}
export function updatedNetwork() {
  return {
    type: 'UPDATED',
  }
}

export function createdNetwork() {
  return {
    type: 'CREATED',
  }
}
export function deletedNetwork() {
  return {
    type: 'DELETED',
  }
}
