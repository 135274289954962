interface PayoutState {
  payout: any
  payout_count: number
  balance: number
}

const initialState: PayoutState = {
  payout: {},
  payout_count: 0,
  balance: 0,
}

export function payoutState(state = initialState, action: any) {
  switch (action.type) {
    case 'PAYOUT/LIST_PAYOUTS':
      return {
        ...state,
        payout: {
          ...state.payout,
          [action.page]: action.payout,
        },
        payout_count: action.count,
      }
    case 'PAYOUT/SUMMARY':
      return {
        ...state,
        balance: action.balance,
      }
    default:
      return state
  }
}
