import React from 'react'
import { useDispatch } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

import { crypt } from '../helpers'
import { loginAction } from '../actions'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch()
  const userInfo = crypt.getUserInfo()

  if (
    localStorage.getItem('auth') &&
    localStorage.getItem('login') &&
    (
      (Date.now() < userInfo.exp * 1000 ||
        Date.now() < parseInt(localStorage.getItem('refresh_token_exp') || '0'))
    )
  ) {
    // Access token still valid, or
    // Access token expired but refresh token still valid
    return <Route {...rest} render={(props) => <Component {...props} />} />
  } else {
    dispatch(loginAction.logout())
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )}
      />
    )
  }
}

export default PrivateRoute
