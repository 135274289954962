import { httpService } from '../helpers/'
import { loginAction } from '../actions'

export const brandAction = {
  getBrandById,
  getProductById,
  sortWholesaleDescending,
  sortWholesaleAscending,
  resetState,
}

function getBrandById(id, params) {
  return (dispatch) => {
    let apiEndpoint = '/brand/' + id + '/preview'
    httpService
      .get(apiEndpoint, {}, params)
      .then((response) => {
        if (response.data.success) {
          console.log(response.data)
          response.data.result.products.rows.forEach((p) => {
            let minWholesaleVariation = p.variations.reduce((prev, current) => {
              return prev.wholesale_price <= current.wholesale_price
                ? prev
                : current
            })
            p.wholesale_price = minWholesaleVariation.wholesale_price
            p.retail_price = minWholesaleVariation.retail_price
          })
          dispatch({
            type: 'GET_BRAND',
            result: {
              brand: response.data.result.brand,
              products: {
                page: params.page,
                rows: response.data.result.products.rows,
              },
              count: Math.ceil(
                response.data.result.products.count / response.data.itemsPerPage
              ),
              collections: response.data.result.collections,
            },
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function getProductById(productId) {
  return (dispatch) => {
    let apiEndpoint = '/brand/preview/product/' + productId
    httpService
      .get(apiEndpoint)
      .then((response) => {
        console.log(response.data)
        if (response.data.success) {
          dispatch({
            type: 'GET_PRODUCT',
            result: response.data.result,
            related: response.data.related,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function sortWholesaleDescending() {
  return (dispatch) => {
    dispatch({
      type: 'SORT_WHOLESALE_DESCENDING',
    })
  }
}
function sortWholesaleAscending() {
  return (dispatch) => {
    dispatch({
      type: 'SORT_WHOLESALE_ASCENDING',
    })
  }
}
function resetState() {
  return (dispatch) => {
    dispatch({ type: 'RESET_BRAND_STATE' })
  }
}
