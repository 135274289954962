const initialState = {
  products: [],
  regions: [],
  promotions: [],
  current_promotion: {},
  redeemed: 0,
}
export function promotion(state = initialState, action) {
  switch (action.type) {
    case 'PROMOTION/LIST_PRODUCTS':
      return {
        ...state,
        products: action.products,
        regions: action.regions,
      }
    case 'PROMOTION/LIST_PROMOTIONS':
      return {
        ...state,
        promotions: action.result,
      }
    case 'PROMOTION/GET_PROMOTION_BY_ID':
      return {
        ...state,
        current_promotion: action.result,
        redeemed: action.redeemed,
      }
    case 'PROMOTION/ENABLE_PROMOTION':
      return {
        ...state,
        current_promotion: {
          ...state.current_promotion,
          is_disabled: false,
        },
      }
    case 'PROMOTION/DISABLE_PROMOTION':
      return {
        ...state,
        current_promotion: {
          ...state.current_promotion,
          is_disabled: true,
        },
      }
    default:
      return state
  }
}
