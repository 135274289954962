import {
  crypt,
  history,
  httpService,
  Segment,
  SegmentTrackEvent,
} from '../helpers'

const listCollections = () => (dispatch) => {
  const apiEndpoint = '/product-collection'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'PRODUCT_COLLECTION/LIST_COLLECTIONS',
          result: response.data.result,
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const createCollection = (payload) => (dispatch) => {
  const apiEndpoint = '/product-collection'
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        const userInfo = crypt.getUserInfo()

        Segment.track(SegmentTrackEvent.CREATE_PRODUCT_COLLECTION, {
          brand_id: userInfo.company.id,
          brand_name: userInfo.company.name,
          collection_id: response.data.result.id,
        })
        dispatch({
          type: 'PRODUCT_COLLECTION/CREATE',
          result: response.data.result,
        })
        history.push('/collection/' + response.data.result.id)
        localStorage.setItem('collection', 'created')
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const getCollectionById = (id) => (dispatch) => {
  const apiEndpoint = '/product-collection/' + id
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'PRODUCT_COLLECTION/CURRENT_COLLECTION',
          result: response.data.result,
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const getCollectionByIdPreview = (id) => (dispatch) => {
  const apiEndpoint = '/product-collection/preview/' + id
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        window.scrollTo(0, 0)
        dispatch({
          type: 'PRODUCT_COLLECTION/PREVIEW_CURRENT_COLLECTION',
          result: response.data.result,
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const getAllCollectionsPreview = () => (dispatch) => {
  const apiEndpoint = '/product-collection/preview/all'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'PRODUCT_COLLECTION/PREVIEW_ALL_COLLECTION',
          result: response.data.result,
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const getProducts = () => (dispatch) => {
  const apiEndpoint = '/product-collection/products'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'PRODUCT_COLLECTION/GET_PRODUCTS',
          result: response.data.result,
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const updateTranslation =
  (payload, setSaveResult, setTranslationForm) => (dispatch) => {
    const apiEndpoint = '/product-collection/translation'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          dispatch({
            type: 'PRODUCT_COLLECTION/UPDATE_TRANSLATION',
            result: response.data.result,
          })
          setSaveResult({
            open: true,
            result: 'success',
            disabled: false,
          })
          setTranslationForm({ open: false })
        } else {
          setSaveResult({
            open: true,
            result: 'error',
            disabled: false,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

const deleteCollection = (id) => (dispatch) => {
  const apiEndpoint = '/product-collection/' + id
  httpService
    .del(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        window.scrollTo(0, 0)
        history.push('/collection')
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const editCollection = (id, payload, setSaveResult) => (dispatch) => {
  const apiEndpoint = '/product-collection/edit/' + id
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'PRODUCT_COLLECTION/EDIT',
          result: response.data.result,
        })
        history.push('/collection/' + id)
        localStorage.setItem('collection', 'edited')
      } else {
        setSaveResult({
          open: true,
          result: 'error',
          disabled: false,
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const productCollectionAction = {
  getProducts,
  createCollection,
  listCollections,
  getCollectionById,
  updateTranslation,
  deleteCollection,
  editCollection,
  getCollectionByIdPreview,
  getAllCollectionsPreview,
}
