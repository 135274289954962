import pdfMake from 'pdfmake/build/pdfmake'

export const pdfHelper = {
  createPdfFile,
}

function createPdfFile(content) {
  pdfMake.fonts = {
    NotoSans: {
      normal:
        'https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Regular.woff2',
      bold: 'https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Bold.woff2',
    },
  }

  pdfMake.createPdf(content).open()
}
