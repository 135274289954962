const initialState = {
  sell_through: 0,
  total_returns: 0,
  avg_return_items: 0,
  top_selling: [],
  top_returned: [],
  top_return_reasons: [],
  return_comments: [],
}

export function sell_through(state = initialState, action) {
  switch (action.type) {
    case 'SELL_THROUGH/SUMMARY':
      return {
        ...state,
        sell_through: action.sell_through,
        total_returns: action.total_returns,
        avg_return_items: action.avg_return_items,
      }
    case 'SELL_THROUGH/TOP_SELLING':
      return {
        ...state,
        top_selling: action.top_selling,
      }
    case 'SELL_THROUGH/TOP_RETURNED':
      return {
        ...state,
        top_returned: action.top_returned,
      }
    case 'SELL_THROUGH/TOP_RETURN_REASONS':
      return {
        ...state,
        top_return_reasons: action.top_return_reasons,
      }
    case 'SELL_THROUGH/RETURN_COMMENTS':
      return {
        ...state,
        return_comments: action.return_comments,
      }
    default:
      return state
  }
}
