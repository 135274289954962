import { httpService, history } from '../helpers'
import { loginAction } from '.'

export const brandListAction = {
  getBrandTags,
  addBrandTag,
  editBrandTag,
  removeBrandTag,
}
function getBrandTags() {
  return (dispatch) => {
    let apiEndpoint = '/brand-tag/get-tags'
    httpService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'GET_BRAND_LIST',
            brands: response.data.result.rows,
            count: response.data.result.count,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function addBrandTag(payload) {
  return (dispatch) => {
    let apiEndpoint = `/brand-tag/add`
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'ADD_NEW_BRAND',
            brand_tag: response.data.result,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function editBrandTag(payload) {
  return (dispatch) => {
    let apiEndpoint = `/brand-tag/edit`
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'EDIT_BRAND',
            brand: response.data.result,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function removeBrandTag(payload) {
  return (dispatch) => {
    let apiEndpoint = `/brand-tag/remove`
    httpService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'REMOVE_BRAND',
            brand: payload,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
