import { httpService } from '../helpers'

export const setCurrency = (currencyId) => (dispatch) => {
  dispatch({
    type: 'CURRENCY/SET_CURRENCY',
    currency_id: currencyId,
  })
}

export const getCurrencies = () => (dispatch) => {
  const apiEndpoint = '/currency/'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'CURRENCY/GET_CURRENCY',
          result: response.data.result,
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const getBrandCurrencies = () => (dispatch) => {
  const apiEndpoint = '/currency/brand'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      console.log(response)
      if (response.data.success) {
        dispatch({
          type: 'CURRENCY/GET_BRAND_CURRENCY',
          result: response.data.result,
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
