let nonce = localStorage.getItem('nonce')
let auth = localStorage.getItem('auth')

const initialState = auth ? { loggingIn: true, auth, nonce } : {}
export function authentication(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        loggingIn: true,
        auth: action.auth,
        nonce: action.nonce,
      }

    case 'LOGOUT_SUCCESS':
      return {
        auth: false,
      }
    default:
      return state
  }
}
