import { crypt } from '../helpers'

const initialState = {
  brands: [],
  brand_options: [],
  count: 0,
}
export function brand_list(state = initialState, action) {
  // const userInfo = crypt.getUserInfo()
  // const languageCode = localStorage.getItem('language_code')
  switch (action.type) {
    case 'GET_BRAND_LIST':
      let brand_options = getBrandOptions(action.brands)
      return {
        ...state,
        brands: action.brands,
        brand_options: brand_options,
        count: action.count,
      }
    case 'ADD_NEW_BRAND':
      state.brands.push(action.brand_tag)
      return {
        ...state,
        count: state.count + 1,
        brand_options: getBrandOptions(state.brands),
      }
    case 'EDIT_BRAND':
      let objIndex = state.brands.findIndex(
        (obj) => obj.id === action?.brand?.id
      )
      state.brands[objIndex] = action?.brand
      return {
        ...state,
        brand_options: getBrandOptions(state.brands),
      }
    case 'REMOVE_BRAND':
      state.brands = state.brands.filter(function (item) {
        return item?.name !== action?.brand?.name
      })
      return {
        ...state,
        count: state.count - 1,
        brand_options: getBrandOptions(state.brands),
      }
    default:
      return state
  }
}

const getBrandOptions = (brands) => {
  let brand_options = []
  if (brands?.length > 0) {
    for (let brand of brands) {
      brand_options.push({
        value: brand.id,
        label: brand.name,
      })
    }
  }
  return brand_options
}
