import { loginAction } from '../actions'
import { httpService } from '../helpers'

const getSummary = (daysBack = 0) => (dispatch) => {
  const apiEndpoint = '/sell-through/summary'
  httpService
    .get(apiEndpoint, {}, { daysBack: daysBack })
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'SELL_THROUGH/SUMMARY',
          sell_through: (response.data.result.order_quantity - response.data.result.return_quantity) / response.data.result.order_quantity * 100,
          total_returns: parseInt(response.data.result.total_returns),
          avg_return_items: response.data.result.return_quantity / response.data.result.total_returns,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const getTopSelling = (daysBack = 0) => (dispatch) => {
  const apiEndpoint = '/sell-through/top-selling'
  httpService
    .get(apiEndpoint, {}, { daysBack: daysBack })
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'SELL_THROUGH/TOP_SELLING',
          top_selling: response.data.result,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const getTopReturned = (daysBack = 0) => (dispatch) => {
  const apiEndpoint = '/sell-through/top-returned'
  httpService
    .get(apiEndpoint, {}, { daysBack: daysBack })
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'SELL_THROUGH/TOP_RETURNED',
          top_returned: response.data.result,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const getTopReturnReasons = (daysBack = 0) => (dispatch) => {
  const apiEndpoint = '/sell-through/top-return-reasons'
  httpService
    .get(apiEndpoint, {}, { daysBack: daysBack })
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'SELL_THROUGH/TOP_RETURN_REASONS',
          top_return_reasons: response.data.result,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const getReturnComments = (daysBack = 0) => (dispatch) => {
  const apiEndpoint = '/sell-through/return-comments'
  httpService
    .get(apiEndpoint, {}, { daysBack: daysBack })
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'SELL_THROUGH/RETURN_COMMENTS',
          return_comments: response.data.result,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const sellThroughAction = {
  getSummary,
  getTopSelling,
  getTopReturned,
  getTopReturnReasons,
  getReturnComments,
}
