import { httpService } from '../helpers/'
import { loginAction } from '../actions'

export const storeAction = {
  getStore,
  editStore,
  editSocial,
  editStoreImages,
  editStoreDetails,
  addRegionSpecificName,
  removeSpecificName,
}
function getStore() {
  return (dispatch) => {
    let apiEndpoint = '/store'
    httpService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'GET_STORE',
            store: response.data.result,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function editStore(payload, setSaveResult) {
  return (dispatch) => {
    let apiEndpoint = '/store'
    httpService.put(apiEndpoint, payload).then((response) => {
      if (response.data.success) {
        setSaveResult({ open: true, result: 'success', disabled: false })
        dispatch({
          type: 'UPDATED_STORE',
          store: response.data.result,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      } else {
        setSaveResult({ open: true, result: 'error', disabled: false })
      }
    })
  }
}
function editSocial(payload, setSaveResult) {
  return (dispatch) => {
    let apiEndpoint = '/store/social'
    httpService.put(apiEndpoint, payload).then((response) => {
      if (response.data.success) {
        setSaveResult({ open: true, result: 'success', disabled: false })
        dispatch({
          type: 'UPDATED_SOCIAL',
          store: payload,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      } else {
        setSaveResult({ open: true, result: 'error', disabled: false })
      }
    })
  }
}
function editStoreImages(payload, setSaveResult) {
  return (dispatch) => {
    let apiEndpoint = '/store/images'
    httpService.put(apiEndpoint, payload).then((response) => {
      if (response.data.success) {
        setSaveResult({ open: true, result: 'success', disabled: false })
        dispatch({
          type: 'UPDATED_STORE',
          store: response.data.result,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      } else {
        setSaveResult({ open: true, result: 'error', disabled: false })
      }
    })
  }
}
const parseBool = (value) => {
  return value === 'true'
}
function editStoreDetails(payload, setSaveResult) {
  return (dispatch) => {
    let apiEndpoint = '/store/details'
    httpService.put(apiEndpoint, payload).then((response) => {
      if (response.data.success) {
        setSaveResult({ open: true, result: 'success', disabled: false })
        const updateValues = {
          id: response.data.result.id
            ? response.data.result.id
            : payload.get('company_values_id'), //only returns id if create, if update, uses the original id
          handmade: parseBool(payload.get('handmade')),
          eco_friendly: parseBool(payload.get('eco_friendly')),
          organic: parseBool(payload.get('organic')),
          socially_responsible: parseBool(payload.get('socially_responsible')),
          sustainable: parseBool(payload.get('sustainable')),
          women_owned: parseBool(payload.get('women_owned')),
          limited_production: parseBool(payload.get('limited_production')),
          not_on_taobao: parseBool(payload.get('not_on_taobao')),
          not_on_amazon: parseBool(payload.get('not_on_amazon')),
        }
        const payload_store = {
          display_name: payload.get('display_name'),
          brand_story_en: payload.get('brand_story_en'),
          brand_story_zh_cn: payload.get('brand_story_zh_cn'),
          brand_story_zh_tw: payload.get('brand_story_zh_tw'),
          tagline_en: payload.get('tagline_en'),
          tagline_zh_cn: payload.get('tagline_zh_cn'),
          tagline_zh_tw: payload.get('tagline_zh_tw'),
          year_established: parseInt(payload.get('year_established')),
          origin_country: payload.get('origin_country'),
          brand_story_photo:
            payload.get('brand_story_photo') !== 'null'
              ? payload.get('brand_story_photo')
              : null,
          brand_story_video:
            payload.get('brand_story_video') !== 'null'
              ? payload.get('brand_story_video')
              : null,
          brand_video_embed_code:
            payload.get('brand_video_embed_code') !== 'null' &&
            payload.get('brand_video_embed_code') !== ''
              ? payload.get('brand_video_embed_code')
              : null,
        }
        dispatch({
          type: 'UPDATED_STORE_DETAILS',
          store: payload_store,
          company_values: updateValues,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      } else {
        setSaveResult({ open: true, result: 'error', disabled: false })
      }
    })
  }
}
function addRegionSpecificName(payload, setSaveResult) {
  return (dispatch) => {
    let apiEndpoint = '/store/specific-name'
    httpService.post(apiEndpoint, payload).then((response) => {
      if (response.data.success) {
        setSaveResult({ open: true, result: 'success', disabled: false })
        dispatch({
          type: 'ADD_REGION_SPECIFIC_NAME',
          result: response.data.result,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      } else {
        setSaveResult({ open: true, result: 'error', disabled: false })
      }
    })
  }
}

function removeSpecificName(payload, setSaveResult) {
  return (dispatch) => {
    let apiEndpoint = '/store/remove-name'
    httpService.post(apiEndpoint, payload).then((response) => {
      if (response.data.success) {
        setSaveResult({ open: true, result: 'success', disabled: false })
        dispatch({
          type: 'REMOVE_SPECIFIC_NAME',
          region_id: payload.region_id,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      } else {
        setSaveResult({ open: true, result: 'error', disabled: false })
      }
    })
  }
}
