import React, { Component, Suspense, lazy } from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { renderToStaticMarkup } from 'react-dom/server'
import { LocalizeProvider } from 'react-localize-redux'

import './App.css'

import { history } from './helpers'
import translations from './translations.json'
import { theme } from './theme'

import { ThemeProvider } from '@material-ui/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import config from './config/config'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import PrivateRoute from './components/PrivateRoute'
import PrivateRouteWithSidebar from './components/PrivateRouteWithSidebar'

//Do not code split this
//const PrivateRoute = lazy(() => import('./components/PrivateRoute'))
const Order = lazy(() => import('./components/order/order'))
const OrderDetail = lazy(() => import('./components/order/orderDetail'))
const ForgotPassword = lazy(() => import('./components/login/forgotPassword'))
const ProductPreview = lazy(() => import('./components/preview/product'))
const BrandPreview = lazy(() => import('./components/preview/BrandPreview'))
const Login = lazy(() => import('./components/login/login'))
const SelectBrand = lazy(() => import('./components/login/SelectBrand'))
const Settings = lazy(() => import('./components/settings/settings'))
const Store = lazy(() => import('./components/store/store'))
const Home = lazy(() => import('./components/home/home'))
const Product = lazy(() => import('./components/product/product'))
const Network = lazy(() => import('./components/network/network'))
const NetworkDetail = lazy(() => import('./components/network/NetworkDetail'))
const Analytics = lazy(() => import('./components/analytics/analytics'))
const CreateOrder = lazy(() => import('./components/order/createOrder'))
const EditManualOrder = lazy(() => import('./components/order/editManualOrder'))
const EditAvailability = lazy(
  () => import('./components/order/editAvailability')
)
const OnboardForm = lazy(() => import('./components/login/OnboardForm'))
const BrandChangeRequest = lazy(
  () => import('./components/forms/BrandChangeRequest')
)
const Promotion = lazy(() => import('./components/promotion/promotion'))
const CreatePromotion = lazy(
  () => import('./components/promotion/createPromotion')
)
const PromotionDetails = lazy(
  () => import('./components/promotion/promotionDetails')
)
const EditPromotion = lazy(() => import('./components/promotion/editPromotion'))
const SharingLinks = lazy(() => import('./components/direct_link/sharingLinks'))
const AddProduct = lazy(() => import('./components/product/addProduct'))
const EditProduct = lazy(() => import('./components/product/editProduct'))
const RearrangeProducts = lazy(
  () => import('./components/product/rearrangeProducts')
)
const WidgetMain = lazy(() => import('./components/widget/WidgetMain'))
const ChangePassword = lazy(() => import('./components/login/changePassword'))
const CurrencyTable = lazy(
  () => import('./components/currency_table/CurrencyTable')
)
const Messages = lazy(() => import('./components/messages/chat'))
const PeebaDirect = lazy(() => import('./components/peeba_direct/peebaDirect'))
const PayoutList = lazy(() => import('./components/payout/PayoutList'))
const ProductCollection = lazy(
  () => import('./components/product_collection/collection')
)
const CreateProductCollection = lazy(
  () => import('./components/product_collection/createCollection')
)
const CollectionDetails = lazy(
  () => import('./components/product_collection/collectionDetails')
)
const EditCollection = lazy(
  () => import('./components/product_collection/editCollection')
)
const CollectionPreview = lazy(
  () => import('./components/preview/productCollection')
)
const BankingInformation = lazy(
  () => import('./components/banking/BankInformation')
)

const stripePromise = loadStripe(config.stripe_pk)
class App extends Component {
  render() {
    return (
      <Elements stripe={stripePromise}>
        <LocalizeProvider
          initialize={{
            languages: [
              { name: 'English', code: 'en' },
              { name: 'Chinese (Simplified)', code: 'zh_CN' },
              { name: 'Chinese (Traditional)', code: 'zh_TW' },
            ],
            translation: translations,
            options: {
              renderToStaticMarkup,
              defaultLanguage: localStorage.getItem('language_code') || 'en',
            },
          }}
        >
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Router history={history}>
                <Suspense fallback={<div />}>
                  <Switch>
                    <PrivateRouteWithSidebar
                      exact
                      path="/home"
                      component={Home}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/analytics"
                      component={Analytics}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/order"
                      component={Order}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/order/new"
                      component={CreateOrder}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/order/new/:id"
                      component={EditManualOrder}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/order/edit/:id"
                      component={EditAvailability}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/order/:id"
                      component={OrderDetail}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/product"
                      component={Product}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/product/new"
                      component={AddProduct}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/product/rearrange"
                      component={RearrangeProducts}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/product/:id"
                      component={EditProduct}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/promotion/edit/:id"
                      component={EditPromotion}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/promotion/new"
                      component={CreatePromotion}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/promotion/:id"
                      component={PromotionDetails}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/promotion"
                      component={Promotion}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/payout"
                      component={PayoutList}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/direct-link"
                      component={SharingLinks}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/network"
                      component={Network}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/network/:id"
                      component={NetworkDetail}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/banking"
                      component={BankingInformation}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/settings/:setting"
                      component={Settings}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/store"
                      component={Store}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/messages"
                      component={Messages}
                    />
                    {/* <PrivateRouteWithSidebar exact path="/balance" component={Balance} /> */}
                    <PrivateRoute
                      exact
                      path="/preview"
                      component={BrandPreview}
                    />
                    <PrivateRoute
                      exact
                      path="/preview/product/:productId"
                      component={ProductPreview}
                    />
                    <PrivateRoute
                      exact
                      path="/preview/collection/:collectionId"
                      component={CollectionPreview}
                    />
                    <PrivateRoute
                      exact
                      path="/select_brand"
                      component={SelectBrand}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/widget"
                      component={WidgetMain}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/change-password"
                      component={ChangePassword}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/currency-table"
                      component={CurrencyTable}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/peeba-direct"
                      component={PeebaDirect}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/collection"
                      component={ProductCollection}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/collection/new"
                      component={CreateProductCollection}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/collection/:id"
                      component={CollectionDetails}
                    />
                    <PrivateRouteWithSidebar
                      exact
                      path="/collection/edit/:id"
                      component={EditCollection}
                    />
                    <Route exact path="/" component={Login} />
                    <Route
                      exact
                      path="/forgotpassword"
                      component={ForgotPassword}
                    />
                    <Route
                      exact
                      path="/onboard/:lang"
                      component={OnboardForm}
                    />
                    <Route
                      exact
                      path="/change-request"
                      component={BrandChangeRequest}
                    />
                  </Switch>
                </Suspense>
              </Router>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </LocalizeProvider>
      </Elements>
    )
  }
}
export default App
