const initialState = {
  organization: {},
}
export function organization(state = initialState, action) {
  switch (action.type) {
    case 'ORGANIZATION/GET_BRANDS':
      return {
        ...state,
        organization: action.organization,
      }
    default:
      return state
  }
}
