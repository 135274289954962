import { crypt, httpService, Segment, SegmentTrackEvent } from '../helpers'

const submitWebsite = (payload, setSubmitted, setErrorMessage, setIsWidgetVerifying) => (dispatch) => {
  const apiEndpoint = '/peeba-direct'
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        const userInfo = crypt.getUserInfo()

        dispatch({
          type: 'PEEBA_DIRECT/SUBMIT_WEBSITE',
        })
        Segment.track(SegmentTrackEvent.PEEBA_DIRECT_SUBMIT, {
          brand_id: userInfo.company.id,
          brand_name: userInfo.company.name,
          website: payload.website,
        })
        setSubmitted(true)
        setErrorMessage(null)
      } else {
        setErrorMessage(response.data.message)
      }
      setIsWidgetVerifying(false)
    })
    .catch((err) => {
      console.log(err)
    })
}

const emailCode = (payload, setSubmitted) => (dispatch) => {
  const apiEndpoint = '/peeba-direct/email'
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        setSubmitted(true)
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const peebaDirectAction = {
  submitWebsite,
  emailCode,
}
