import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0DA0FB',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#0078D7',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily:
      '"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    h4: {
      fontSize: 32,
      fontWeight: 600,
      lineHeight: '56px',
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '18px',
      color: '#252525',
    },
    body1: {
      fontSize: 13,
      fontWeight: 600,
      lineHeight: '16px',
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 13,
      lineHeight: '24px',
      color: '#5E5F61',
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '24px',
      color: '#252525',
    },
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#042360',
        boxShadow: 'none',
      },
    },
    MuiToolbar: {
      dense: {
        height: 56,
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#05C73B',
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#33B1FE',
          boxShadow: 'none',
        },
      },
      outlined: {
        '&:hover': {
          border: '1px solid #B6B6B8',
          backgroundColor: '#FFFFFF',
        },
      },
      outlinedPrimary: {
        '&:hover': {
          border: '1px solid #B6B6B8',
          backgroundColor: '#FFFFFF',
        },
      },
      outlinedSecondary: {
        '&:hover': {
          border: '1px solid #B6B6B8',
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiOutlinedInput: {
      multiline: {
        width: 'auto',
        height: 'auto',
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },
    MuiInputBase: {
      root: {
        width: 246,
        height: 36,
      },
    },
    MuiInputLabel: {
      outlined: {
        color: '#5E5F61',
        fontSize: 16,
      },
      formControl: {
        top: -10,
        left: -14,
      },
    },
    MuiDialog: {
      container: {
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
      },
      paper: {
        margin: 0,
      },
      paperScrollPaper: {
        height: '100vh',
        maxHeight: '100%',
      },
      paperWidthSm: {
        maxWidth: 580,
      },
      root: {
        '& .AddIconButton': {
          padding: 4,
          background: '#0DA0FB',
          color: '#ffffff',
        },
        '& .AddPhotoButton': {
          width: 68,
          height: 68,
          border: 0,
          borderRadius: 0,
          background: '#EFF5F8',
        },
        '& .DialogHeaderRight': {
          marginLeft: 'auto',
        },
        '& .Photo': {
          maxHeight: 68,
          maxWidth: 68,
          top: '50%',
          transform: 'translateY(-50%)',
          position: 'relative',
        },
        '& .PhotoClose': {
          position: 'absolute',
          transform: 'translate(-8px, -8px)',
          background: '#E63337',
          color: '#ffffff',
          padding: 0,
        },
        '& .PhotoWrapper': {
          position: 'relative',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        height: 24,
        backgroundColor: '#042360',
        color: '#C5E0F4',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    MuiDialogContent: {
      root: {
        padding: 24,
      },
    },
    MuiDialogActions: {
      root: {
        padding: 24,
        justifyContent: 'flex-start',
      },
    },
    MuiGrid: {
      root: {
        '& .DetailsTabs': {
          paddingLeft: 40,
          background: '#EFF5F8',
        },
        '& .Content': {
          flexGrow: 1,
          padding: 40,
        },
        '& .Subheader': {
          display: 'flex',
        },
      },
    },
    MuiTableCell: {
      head: {
        fontSize: 11,
        color: '#89898A',
        textTransform: 'uppercase',
        paddingBottom: 4,
      },
      body: {
        borderBottom: 'none',
        verticalAlign: 'top',
        color: '#89898A',
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(even)': {
          background: '#FBFBFB',
        },
      },
    },
    MuiTablePagination: {
      input: {
        width: 'unset',
      },
    },
    MuiTab: {
      root: {
        marginRight: 8,
        '&$selected': {
          background: '#ffffff',
          borderRadius: '4px 4px 0 0',
        },
      },
      textColorPrimary: {
        minWidth: 0,
      },
      textColorSecondary: {
        minWidth: 0,
      },
    },
    MuiTabs: {
      indicator: {
        display: 'none',
      },
    },
  },
})
