const config = {
  baseUrl: process.env.REACT_APP_BASE_URL as string,
  stripe_pk: process.env.REACT_APP_STRIPE_PK as string,
  productUrl: process.env.REACT_APP_PRODUCT_URL as string,
  storeUrl: process.env.REACT_APP_STORE_URL as string,
  sendBirdAppId: process.env.REACT_APP_SENDBIRD_ID as string,
  fileUrl: process.env.REACT_APP_FILE_URL as string,
}

export default config
