const initialState = {
  superbrand_trial: false,
  directOrdersCount: 0,
  rating: 0,
  responseRate: null,
}
export function superbrand(state = initialState, action) {
  switch (action.type) {
    case 'GET_SUPERBRAND_STATS':
      return {
        superbrand_trial: action.brand.superbrand_trial,
        directOrdersCount: action.directOrders,
        rating: action.rating,
        responseRate: action.responseRate,
      }
    default:
      return state
  }
}
