import { loginAction } from '.'
import { httpService } from '../helpers'

const daysToMs = 24 * 60 * 60 * 1000

export const dashboardAction = {
  getOrderTotals,
  getClickTotals,
  getMonthlyOrders,
  getMonthlyClicks,
  getMonthlySellThrough,
  getMonthlyReviews,
}
function getOrderTotals(daysBack = 0) {
  return (dispatch) => {
    let apiEndpoint = '/dashboard/orderTotals'
    httpService
      .get(apiEndpoint, {}, { daysBack: daysBack })
      .then((response: any) => {
        if (response.data.success) {
          dispatch({
            type: 'DASHBOARD/ORDER_TOTALS',
            order_volume: response.data.result.total || 0,
            total_orders: parseInt(response.data.result.count),
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getClickTotals(daysBack = 0) {
  return (dispatch) => {
    let apiEndpoint = '/dashboard/clickTotals'
    httpService
      .get(apiEndpoint, {}, { daysBack: daysBack })
      .then((response: any) => {
        if (response.data.success) {
          dispatch({
            type: 'DASHBOARD/CLICK_TOTALS',
            total_clicks: parseInt(response.data.result.count),
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getMonthlyOrders(daysBack = 0) {
  return (dispatch) => {
    let apiEndpoint = '/dashboard/monthlyOrders'
    httpService
      .get(apiEndpoint, {}, { daysBack: daysBack })
      .then((response: any) => {
        if (response.data.success) {
          let timeRangeHash = {}
          let daysBackAllTime = Math.floor(
            (new Date().getTime() -
              new Date(response.data.result.company.created_at).getTime()) /
              daysToMs
          )
          for (let i = daysBack ? daysBack : daysBackAllTime; i > 0; i--) {
            let date = new Date(new Date().getTime() - i * daysToMs)
            timeRangeHash[date.toISOString().substring(0, 10)] = {
              total: 0,
              count: 0,
              date: date.toDateString(),
            }
          }
          for (let day of response.data?.result?.orders) {
            let isoString = day.epoch.substring(0, 10)
            timeRangeHash[isoString] = {
              ...timeRangeHash[isoString],
              total: day.total,
              count: day.count,
            }
          }
          let orderData = Object.keys(timeRangeHash).map((time) => {
            return {
              total: parseFloat(timeRangeHash[time].total),
              count: parseInt(timeRangeHash[time].count),
              date: timeRangeHash[time].date,
              epoch: time,
            }
          })
          dispatch({
            type: 'DASHBOARD/MONTHLY_ORDERS',
            monthly_orders: orderData,
            monthly_orders_count: response.data?.result?.count,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getMonthlyClicks(daysBack = 0) {
  return (dispatch) => {
    let apiEndpoint = '/dashboard/monthlyClicks'
    httpService
      .get(apiEndpoint, {}, { daysBack: daysBack })
      .then((response: any) => {
        if (response.data.success) {
          let timeRangeHash = {}
          let daysBackAllTime = Math.floor(
            (new Date().getTime() -
              new Date(response.data.result.company.created_at).getTime()) /
              daysToMs
          )
          for (let i = daysBack ? daysBack : daysBackAllTime; i > 0; i--) {
            let date = new Date(new Date().getTime() - i * daysToMs)
            timeRangeHash[date.toISOString().substring(0, 10)] = {
              total: 0,
              date: date.toDateString(),
            }
          }
          for (let day of response.data?.result?.clicks) {
            let time = day.epoch.substring(0, 10)
            timeRangeHash[time] = {
              ...timeRangeHash[time],
              total: day.total,
            }
          }
          let clickData = Object.keys(timeRangeHash).map((time) => {
            return {
              total: parseInt(timeRangeHash[time].total),
              date: timeRangeHash[time].date,
              epoch: time,
            }
          })
          dispatch({
            type: 'DASHBOARD/MONTHLY_CLICKS',
            monthly_clicks: clickData,
            monthly_clicks_count: response.data?.result?.count,
            monthly_customer_count: response.data?.result?.customer_count,
            monthly_referral_count: response.data?.result?.referral_count,
            monthly_marketplace_count: response.data?.result?.marketplace_count,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getMonthlySellThrough(daysBack = 0) {
  return (dispatch) => {
    let apiEndpoint = '/dashboard/monthlySellThrough'
    httpService
      .get(apiEndpoint, {}, { daysBack: daysBack })
      .then((response: any) => {
        if (response.data.success) {
          dispatch({
            type: 'DASHBOARD/MONTHLY_SELL_THROUGH',
            monthly_sell_through:
              ((response.data.result.order_quantity -
                response.data.result.return_quantity) /
                response.data.result.order_quantity) *
              100,
            monthly_returns_count: parseInt(response.data.result.total_returns),
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getMonthlyReviews(daysBack = 0) {
  return (dispatch) => {
    let apiEndpoint = '/dashboard/monthlyReviews'
    httpService
      .get(apiEndpoint, {}, { daysBack: daysBack })
      .then((response: any) => {
        if (response.data.success) {
          dispatch({
            type: 'DASHBOARD/MONTHLY_REVIEWS',
            monthly_rating: parseFloat(response.data.result.reviews.avg_rating),
            monthly_reviews_count: parseInt(response.data.result.reviews.count),
            monthly_5_star_reviews_count: parseInt(
              response.data.result.count_5_reviews
            ),
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
