// Identify user
const identify = (userId, traits = {}, options = {}, callback = undefined) => {
  //@ts-ignore
  window?.analytics.identify(userId, traits, options, callback)
}

// Page view
const page = (event, properties = {}) => {
  //@ts-ignore
  window?.analytics.page(event, properties)
}

// Page event
const track = (event, properties = {}, options = {}, callback = undefined) => {
  //@ts-ignore
  window?.analytics.track(event, properties, options, callback)
}

// Return identified traits
const getUserTraits = () => {
  //@ts-ignore
  return window?.analytics.user().traits()
}

export const Segment = {
  identify,
  page,
  track,
  getUserTraits,
}

export enum SegmentPageEvent {
  ORDER = 'Order Management',
  ORDER_DETAIL = 'Order Detail',
  PRODUCT = 'Product Management',
  PRODUCT_DETAIL = 'Product Detail',
  CUSTOMER = 'Customers',
  CUSTOMER_DETAIL = 'Customer Detail',
  ANALYTICS = 'Analytics',
  DIRECT_LINK = 'Direct Link',
  PROMOTION = 'Promotion Management',
  PROMOTION_DETAIL = 'Promotion Detail',
  STORE = 'Store Management',
  PREVIEW = 'Preview',
  SUPERBRAND = 'Superbrand',
  ADD_PRODUCT = 'Add Product',
  MANUAL_ORDER = 'Manual Order',
  REARRANGE_PRODUCTS = 'Rearrange Products',
  CHAT = 'Chat',
  WIDGET = 'Widget',
  PEEBA_DIRECT = 'Peeba Direct',
  COLLECTIONS = 'Collections',
}

export enum SegmentTrackEvent {
  // Spec: B2B SaaS
  LOGIN = 'Signed In',
  LOGOUT = 'Signed Out',

  // Custom Events
  ORDER_AVAILABILITY_EDITED = 'Order Availability Edited',
  ORDER_ACCEPTED = 'Order Accepted',
  ORDER_CANCELLED = 'Order Cancelled',
  ORDER_ESTIMATED_SHIPPING_DATE_ENTERED = 'Order Estimated Shipping Date Entered',
  ORDER_PACKAGE_SLIP_PRINTED = 'Order Package Slip Printed',
  ORDER_SHIPMENT_PARCEL_ADDED = 'Order Shipment Parcel Added',
  ORDER_SHIPMENT_FAILED_TO_CREATE_SHIPPING_LABEL = 'Order Shipment Failed To Create Shipping Label',
  PEEBA_DIRECT_SUBMIT = 'Peeba Direct Submit',
  CREATE_PRODUCT_COLLECTION = 'Create Product Collection',
}
