const initialState = {
  company_values: {},
}

export function store(state = initialState, action) {
  switch (action.type) {
    case 'GET_STORE':
      return {
        ...state,
        ...action.store,
      }
    case 'UPDATED_STORE':
      return {
        ...state,
        ...action.store,
      }
    case 'UPDATED_SOCIAL':
      return {
        ...state,
        ...action.store,
        social_medium: {
          ...state.social_medium,
          ...action.store,
        },
      }
    case 'UPDATED_STORE_DETAILS':
      return {
        ...state,
        ...action.store,
        company_values: {
          ...state.company_values,
          ...action.company_values,
        },
      }
    case 'ADD_REGION_SPECIFIC_NAME':
      let regionalNames = [...state.regional_company_details]
      regionalNames.push(action.result)
      return {
        ...state,
        regional_company_details: regionalNames,
      }
    case 'REMOVE_SPECIFIC_NAME':
      let regionSpecificNames = [...state.regional_company_details]
      let updatedArr = regionSpecificNames.filter(
        (r) => r.region_id !== action.region_id
      )
      return {
        ...state,
        regional_company_details: updatedArr,
      }
    default:
      return state
  }
}
