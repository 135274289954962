import { httpService } from '../helpers'
import { loginAction } from '../actions'

function getBankInfo() {
  return (dispatch) => {
    let apiEndpoint = '/banking'
    httpService.get(apiEndpoint).then((response: any) => {
      if (response?.data?.success) {
        console.log('result', response.data.result)
        dispatch({
          type: 'BANKING/GET_BANKING_INFO',
          banking_information: response.data.result,
        })
      } else if (response?.data?.logout) {
        dispatch(loginAction.logout())
      }
    })
  }
}

function confirmBankInfo(payload, onSuccess, onFailure) {
  return (dispatch) => {
    let apiEndpoint = '/banking/confirm-bank-info'
    httpService.post(apiEndpoint, payload).then((response: any) => {
      if (response?.data?.success) {
        console.log('result', response.data.result)
        onSuccess()
        dispatch({
          type: 'BANKING/GET_FIELDS',
          banking_schema: response.data.result,
        })
      } else if (response?.data?.logout) {
        dispatch(loginAction.logout())
      } else {
        onFailure()
      }
    })
  }
}

function addBankInfo(payload, onSuccess, onFailure) {
  return (dispatch) => {
    let apiEndpoint = '/banking/'
    httpService.post(apiEndpoint, payload).then((response: any) => {
      if (response?.data?.success) {
        console.log('result', response.data.result)
        onSuccess()
        // dispatch({
        //   type: 'BANKING/GET_FIELDS',
        //   banking_schema: response.data.result,
        // })
      } else if (response?.data?.logout) {
        dispatch(loginAction.logout())
      } else {
        onFailure()
      }
    })
  }
}

export const bankingAction = {
  getBankInfo,
  confirmBankInfo,
  addBankInfo,
}
