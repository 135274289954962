import { crypt } from '../helpers'

const initialState = {
  product: [],
  count: 0,
  current_product: { variations: [] },
  current_product_orders: [],
  close_forms: false,
  category_options: [],
  translations: [],
  autoTranslation: {},
}
export function product(state = initialState, action) {
  const userInfo = crypt.getUserInfo()
  const languageCode = localStorage.getItem('language_code')
  switch (action.type) {
    case 'RESET_PRODUCT':
      return { ...initialState }
    case 'LIST_PRODUCTS':
      let products = []
      if (action.isFilter) {
        products = action.product
      } else {
        products = state.product.concat(action.product)
      }
      return {
        ...state,
        product: products,
        count: action.count,
      }
    case 'GET_PRODUCT_DETAILS':
      state.category_options = []
      let variationOpts = []
      if (action.product.material) variationOpts.push('material')
      if (action.product.color) variationOpts.push('color')
      if (action.product.size) variationOpts.push('size')
      if (action.product.style) variationOpts.push('style')
      if (action.product.version) variationOpts.push('version')
      // variationOpts.push('google_translated')

      state.translations = []

      if (action.product.product_translations) {
        let productTranslationKeys = Object.keys(
          action.product.product_translations[0]
        ).filter((e) => {
          return (
            e !== 'createdAt' &&
            e !== 'created_at' &&
            e !== 'id' &&
            e !== 'language_code' &&
            e !== 'productId' &&
            e !== 'product_id' &&
            e !== 'updatedAt' &&
            e !== 'updated_at'
          )
        })
        for (let key of productTranslationKeys) {
          let translation = { name: key, google_translated: {} }
          if (key !== 'google_translated') {
            for (let pt of action.product.product_translations) {
              translation[pt.language_code] = pt[key]
              translation['google_translated'] = {
                ...translation['google_translated'],
                [pt.language_code]: pt['google_translated'],
              }
            }

            state.translations.push(translation)
          }
        }
      }
      if (action.product.variations) {
        let variationOptionIdMap = {}
        for (let v of action.product.variations) {
          for (let vo of variationOpts) {
            if (v[vo]) {
              variationOptionIdMap[v[vo].id] = { ...v[vo], name: vo }
            }
          }
        }
        for (let vo in variationOptionIdMap) {
          let translation = {
            name: variationOptionIdMap[vo].name,
            variation_option_id: vo,
            google_translated: {},
          }
          for (let vt of variationOptionIdMap[vo].variation_translations) {
            translation[vt.language_code] = vt.option
            translation['google_translated'] = {
              ...translation['google_translated'],
              [vt.language_code]: vt['google_translated'],
            }
          }
          state.translations.push(translation)
        }
      }
      for (let category of action.categories) {
        for (let subcategory of category.subcategories) {
          state.category_options.push({
            value: subcategory.id,
            label: category[languageCode] + ' - ' + subcategory[languageCode],
          })
        }
      }
      state.current_product = {
        ...action.product,
      }
      return state
    case 'GET_PRODUCT_ORDERS':
      return {
        ...state,
        current_product_orders: action.orders,
      }
    case 'UPDATED_PRODUCT':
      state.current_product.product_translations.unshift(
        action.current_product_translation
      )
      state.current_product.product_categories =
        action.current_product_categories
      return {
        ...state,
        close_forms: true,
        current_product: {
          ...state.current_product,
          ...action.current_product,
        },
      }
    case 'DELETED_PRODUCT':
      for (let i = 0; i < state.product.length; i++) {
        if (state.product[i].id === action.id) {
          state.product.splice(i, 1)
        }
      }
      return {
        ...state,
        count: state.count - 1,
      }
    case 'ADDED_VARIATION':
      state.current_product.variations.unshift(action.variation)
      state.close_forms = true
      return state
    case 'EDITED_VARIATION':
      for (let i = 0; i < state.current_product.variations.length; i++) {
        if (state.current_product.variations[i].id === action.variation.id) {
          if (action.material) {
            state.current_product.variations[i]?.material
              ?.variation_translations
              ? state.current_product.variations[
                  i
                ].material.variation_translations.unshift(action.material)
              : (state.current_product.variations[i].material = {
                  variation_translations: [action.material],
                })
          }
          if (action.size) {
            state.current_product.variations[i]?.size?.variation_translations
              ? state.current_product.variations[
                  i
                ].size.variation_translations.unshift(action.size)
              : (state.current_product.variations[i].size = {
                  variation_translations: [action.size],
                })
          }
          if (action.color) {
            state.current_product.variations[i]?.color?.variation_translations
              ? state.current_product.variations[
                  i
                ].color.variation_translations.unshift(action.color)
              : (state.current_product.variations[i].color = {
                  variation_translations: [action.color],
                })
          }
          if (action.style) {
            state.current_product.variations[i]?.style?.variation_translations
              ? state.current_product.variations[
                  i
                ].style.variation_translations.unshift(action.style)
              : (state.current_product.variations[i].style = {
                  variation_translations: [action.style],
                })
          }
          if (action.version) {
            state.current_product.variations[i]?.version?.variation_translations
              ? state.current_product.variations[
                  i
                ].version.variation_translations.unshift(action.version)
              : (state.current_product.variations[i].version = {
                  variation_translations: [action.version],
                })
          }
          state.current_product.variations[i] = {
            ...state.current_product.variations[i],
            ...action.variation,
          }
        }
      }
      state.close_forms = true
      return state
    case 'DELETED_VARIATION':
      for (let i = 0; i < state.current_product.variations.length; i++) {
        if (state.current_product.variations[i].id === action.id) {
          state.current_product.variations.splice(i, 1)
        }
      }
      state.close_forms = true
      return state
    case 'ADDED_FILE':
      action.file.created_by = {
        first_name: userInfo.user.first_name,
        last_name: userInfo.user.last_name,
      }
      state.current_product.product_files.unshift(action.file)
      state.close_forms = true
      return state
    case 'DELETED_FILE':
      for (let i = 0; i < state.current_product.product_files.length; i++) {
        if (state.current_product.product_files[i].id === action.id) {
          state.current_product.product_files.splice(i, 1)
        }
      }
      state.close_forms = true
      return state
    case 'GET_CATEGORIES':
      let categoryOptions = []
      for (let category of action.categories) {
        for (let subcategory of category.subcategories) {
          categoryOptions.push({
            value: subcategory.id,
            label: category[languageCode] + ' - ' + subcategory[languageCode],
          })
        }
      }
      state.category_options = categoryOptions
      return state
    case 'EDIT_ALL_TRANSLATIONS':
      state.translations[action.index] = {
        ...state.translations[action.index],
        en: action.result.en || action.payload.initialValues.en,
        zh_CN: action.result.zh_CN || action.payload.initialValues.zh_CN,
        zh_TW: action.result.zh_TW || action.payload.initialValues.zh_TW,
        google_translated: action.payload.google_translated,
      }
      state.close_forms = true
      return state

    case 'EDITED_TRANSLATION':
      state.translations[action.index] = {
        ...state.translations[action.index],
        [action.result.selectedLanguage]: action.result.text,
        google_translated: action.payload.google_translated,
      }
      state.close_forms = true
      return state
    case 'GET_TRANSLATION':
      state.autoTranslation = {
        text: action.result,
        target: action.target,
      }
      return state
    case 'MARK_AS_BESTSELLER':
      state.current_product = {
        ...state.current_product,
        is_bestseller: true,
      }
      return state
    case 'REMOVE_AS_BESTSELLER':
      state.current_product = {
        ...state.current_product,
        is_bestseller: false,
      }
      return state
    case 'ACTIVATE_PRODUCT':
      state.current_product = {
        ...state.current_product,
        status: 'active',
      }
      return state
    case 'DEACTIVATE_PRODUCT':
      state.current_product = {
        ...state.current_product,
        status: 'inactive',
      }
      return state
    case 'EDIT_PRODUCT':
      state.current_product = {
        ...action.result.product,
        variations: action.result.variations,
        product_categories: action.result.product_categories,
        product_translations: action.result.product_translations,
      }
      return state
    case 'REARRANGE_PRODUCTS':
      return {
        ...state,
        product: action.result.rows,
        count: action.result.count,
      }
    case 'ADD_COUNTRY_PRICE':
      let varsArr = [...state.current_product.variations]
      for (let v of varsArr) {
        let pricebook = action.result.find((p) => p.variationId === v.id)
        pricebook.currency = { currency_code: action.payload.currency_code }
        v.pricebooks = v.pricebooks.concat(pricebook)
        v.not_replaced = true
      }

      return {
        ...state,
        current_product: {
          ...state.current_product,
          variations: varsArr,
        },
      }

    default:
      return state
  }
}
