interface ProductCollectionState {
  collections: any
  products: any
  current_collection: any
}

const initialState: ProductCollectionState = {
  collections: [],
  products: [],
  current_collection: {
    collection_items: [],
  },
}

export function productCollectionState(state = initialState, action: any) {
  switch (action.type) {
    case 'PRODUCT_COLLECTION/LIST_COLLECTIONS':
      return {
        ...state,
        collections: action.result,
      }
    case 'PRODUCT_COLLECTION/CREATE':
      let updatedCollections = [...state.collections]
      updatedCollections.unshift(action.result)
      return {
        ...state,
        collections: updatedCollections,
      }
    case 'PRODUCT_COLLECTION/CURRENT_COLLECTION':
      return {
        ...state,
        current_collection: action.result,
      }
    case 'PRODUCT_COLLECTION/GET_PRODUCTS':
      return {
        ...state,
        products: action.result,
      }
    case 'PRODUCT_COLLECTION/UPDATE_TRANSLATION':
      return {
        ...state,
        current_collection: {
          ...state.current_collection,
          name_en: action.result.name_en,
          name_zh_cn: action.result.name_zh_cn,
          name_zh_tw: action.result.name_zh_tw,
          description_en: action.result.description_en,
          description_zh_cn: action.result.description_zh_cn,
          description_zh_tw: action.result.description_zh_tw,
        },
      }
    case 'PRODUCT_COLLECTION/PREVIEW_CURRENT_COLLECTION':
      return {
        ...state,
        current_collection: action.result,
      }

    case 'PRODUCT_COLLECTION/PREVIEW_ALL_COLLECTION':
      return {
        ...state,
        collections: action.result,
      }
    default:
      return state
  }
}
