const initialState = {
  order: [],
  count: 0,
  account: {},
  unpaids: []
};

export function balance(state = initialState, action) {
  switch (action.type) {
    case 'RESET_ORDER':
      return { ...initialState }
    case 'LIST_ORDERS_BALANCE':
      console.log(action.unpaids)
      return {
        ...state,
        order: state.order.concat(action.order),
        count: action.count,
        account: action.account,
        unpaids: action.unpaids
      };
    case 'REQUEST_PAYMENT':
      let orders = action.orders
      for (let i = 0; i < orders.length; i++) {
        if (orders[i].id === action.order.id) {
          orders[i] = action.order
          break;
        }
      }
      return {
        ...state,
        order: orders,
      };
    case 'REQUEST_PAYMENT_ALL':
      return {
        ...state,
        order: action.order
      };
    case 'GET_UNPAID_ORDERS':
      return {
        ...state,
        unpaids: action.unpaids
      };
    default:
      return state
  }
}
