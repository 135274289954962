import { httpService } from '../helpers/'
import { loginAction } from '../actions'

function getSettings() {
  return (dispatch) => {
    let apiEndpoint = '/settings'
    httpService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'GET_SETTINGS',
            current_user: response.data.result,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function createUser(payload, onSuccess, onFailure) {
  return (dispatch) => {
    let apiEndpoint = '/settings/user'
    httpService.post(apiEndpoint, payload).then((response) => {
      if (response.data.success) {
        onSuccess()
        dispatch({
          type: 'USER_CREATED',
          new_user: payload,
        })
      } else if (response.data.mailer) {
        onFailure(response.data.error)
      } else if (!response.data.success) {
        onFailure(response.data.error)
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
  }
}
function getUserById(id) {
  return (dispatch) => {
    let apiEndpoint = '/user/' + id
    httpService.get(apiEndpoint).then((response) => {
      dispatch({
        type: 'GET_USER',
      })
    })
  }
}
function editUser(payload, setSaveResult) {
  return (dispatch) => {
    let apiEndpoint = '/settings/user'
    httpService.put(apiEndpoint, payload).then((response) => {
      if (response.data.success) {
        setSaveResult({
          open: true,
          result: 'success',
          disabled: false,
          type: 'change',
        })
        dispatch({
          type: 'UPDATED_USER',
          current_user: response.data.result,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      } else {
        setSaveResult({
          open: true,
          result: 'error',
          disabled: false,
          type: 'change',
        })
      }
    })
  }
}
function changePassword(payload, setSaveResult) {
  return (dispatch) => {
    let apiEndpoint = '/settings/user/password'
    httpService.put(apiEndpoint, payload).then((response) => {
      if (response.data.success) {
        setSaveResult({ open: true, result: 'success', type: 'password' })
        dispatch({
          type: 'CHANGED_PASSWORD',
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      } else {
        setSaveResult({ open: true, result: 'error', type: 'password' })
      }
    })
  }
}
function sendCode(payload) {
  return (dispatch) => {
    let apiEndpoint = '/settings/user/login'
    httpService.put(apiEndpoint, payload).then((response) => {
      if (response.data.success) {
        dispatch({
          type: 'SENT_CODE',
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      } else {
        dispatch({
          type: 'SENT_CODE_ERROR',
          error: response.data.error,
        })
      }
    })
  }
}
function changeLogin(payload, setSaveResult, onSuccess) {
  return (dispatch) => {
    let apiEndpoint = '/settings/user/login'
    httpService.post(apiEndpoint, payload).then((response) => {
      console.log(response)
      if (response.data.success) {
        onSuccess()
        setSaveResult({
          open: true,
          result: 'success',
          disabled: false,
          type: 'change',
        })
        dispatch({
          type: 'CHANGED_LOGIN',
          username: response.data.username,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
  }
}

function deactivateAccount(companyId) {
  return (dispatch) => {
    let apiEndpoint = '/settings/account'
    httpService.put(apiEndpoint, companyId).then((response) => {
      if (response.data.success) {
        dispatch({
          type: 'DEACTIVATE_ACCOUNT',
          active: false,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
  }
}

function getLocations() {
  return (dispatch) => {
    let apiEndpoint = '/settings/locations'
    httpService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data.success) {
          console.log('info')
          console.log(response.data.result)
          dispatch({
            type: 'GET_LOCATIONS',
            locations: response.data.result,
          })
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function editAddress(payload, onSuccess, locations) {
  return (dispatch) => {
    let apiEndpoint = '/settings/locations'
    httpService.post(apiEndpoint, payload).then((response) => {
      if (response.data.success) {
        for (let i = 0; i < locations.length; i++) {
          if (locations[i].id === response.data.result[1][0].id) {
            locations[i] = response.data.result[1][0]
            locations[i].contact = response.data.result2[1][0]
          }
        }
        onSuccess()
        dispatch({
          type: 'UPDATED_ADDRESS',
          locations: locations,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
  }
}

function deactivateAddress(addressId, locations) {
  return (dispatch) => {
    let apiEndpoint = '/settings/locations'
    httpService.put(apiEndpoint, addressId).then((response) => {
      let index = -1
      for (let i = 0; i < locations.length; i++) {
        if (locations[i].id === response.data.result[1][0].id) {
          index = i
          break
        }
      }
      locations.splice(index, 1)
      if (response.data.success) {
        dispatch({
          type: 'DEACTIVATE_ADDRESS',
          locations: locations,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
  }
}

function insertAddress(payload, onSuccess, locations) {
  return (dispatch) => {
    let apiEndpoint = '/store/address'
    httpService.post(apiEndpoint, payload).then((response) => {
      // console.log('response.data')
      // console.log(response.data.res)
      if (locations) {
        locations.push(response.data.result)
      }
      if (response.data.success) {
        onSuccess()
        dispatch({
          type: 'INSERTED_ADDRESS',
          locations: locations,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
  }
}

function getOrderSettings() {
  return (dispatch) => {
    let apiEndpoint = '/settings/order'
    httpService.get(apiEndpoint).then((response) => {
      if (response.data.success) {
        console.log(response)
        dispatch({
          type: 'SETTINGS/ORDER',
          company: response.data.result,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
  }
}

function saveOrderSettings(payload, setSaveResult) {
  return (dispatch) => {
    let apiEndpoint = '/settings/order'
    httpService.post(apiEndpoint, payload).then((response) => {
      if (response.data.success) {
        setSaveResult({
          open: true,
          result: 'success',
          disabled: false,
          type: 'edit',
        })
        dispatch({
          type: 'SETTINGS/ORDER',
          email: payload?.order_notification_email,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
  }
}

function getBankingInfo() {
  return (dispatch) => {
    let apiEndpoint = '/settings/banking'
    httpService.get(apiEndpoint).then((response) => {
      if (response.data.success) {
        dispatch({
          type: 'SETTINGS/GET_BANKING',
          banking: response.data.result,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
  }
}

function saveBankingInfo(payload, onSuccess) {
  return (dispatch) => {
    let apiEndpoint = '/settings/banking'
    httpService.put(apiEndpoint, payload).then((response) => {
      if (response.data.success) {
        onSuccess()
        dispatch({
          type: 'SETTINGS/SET_BANKING',
          banking: payload,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
  }
}

export const settingsAction = {
  getSettings,
  getUserById,
  editUser,
  createUser,
  changePassword,
  sendCode,
  changeLogin,
  deactivateAccount,
  getLocations,
  editAddress,
  deactivateAddress,
  insertAddress,
  getOrderSettings,
  saveOrderSettings,
  getBankingInfo,
  saveBankingInfo,
}
