import { httpService } from '../helpers/'
import { loginAction } from '../actions'

const getHomeInfo = () => (dispatch) => {
  const apiEndpoint = '/home/brand-home'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'GET_HOME_INFO',
          products: response.data.result.products,
          orders: response.data.result.orders,
          messages: response.data.result.messages,
          collectionExists: response.data.result.collectionExists,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const getCompanyInfo = () => (dispatch) => {
  const apiEndpoint = '/home/company'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'GET_COMPANY_INFO',
          company: response.data.result,
          directTotals: response.data.directTotals,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const getCategories = () => (dispatch) => {
  const apiEndpoint = '/home/categories'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'GET_FIRST_LOGIN_CATEGORIES',
          result: response.data.result,
        })
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const checkSlug = (payload, nextPage, handleSubmit) => (dispatch) => {
  const apiEndpoint = '/home/slug'
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: 'CHECK_SLUG',
          slugExists: response.data.slugExists,
        })
        handleSubmit()
        if (!response.data.slugExists) nextPage()
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const submitFirstLogin = (payload) => (dispatch) => {
  const apiEndpoint = '/home/first-login'
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
      } else if (response.data.logout) {
        dispatch(loginAction.logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

function getNotifications(params, setShowPeebaDirectPopup) {
  return (dispatch) => {
    let apiEndpoint = '/home/notifications'
    httpService
      .get(apiEndpoint, {}, params)
      .then((response: any) => {
        if (response.data.success) {
          dispatch({
            type: 'NEW_NOTIFICATIONS',
            newMessages: response.data.newMessages,
            newOrders: response.data.newOrders,
            brand: response.data.brand,
          })
          setShowPeebaDirectPopup(response.data.showPeebaDirectAnnouncement)
        } else if (response.data.logout) {
          dispatch(loginAction.logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function removeMessageNotification() {
  return (dispatch) => {
    dispatch({
      type: 'REMOVE_NEW_MESSAGE_NOTIFICATION',
    })
  }
}

function removeOrderNotification() {
  return (dispatch) => {
    dispatch({
      type: 'REMOVE_NEW_ORDER_NOTIFICATION',
    })
  }
}

export const homeAction = {
  getHomeInfo,
  getCompanyInfo,
  getCategories,
  submitFirstLogin,
  checkSlug,
  getNotifications,
  removeMessageNotification,
  removeOrderNotification,
}
